import React, { useState,useCallback } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  styled,
  InputBase,
} from "@mui/material";

import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import RoofingOutlinedIcon from "@mui/icons-material/RoofingOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { Search as SearchIcon } from "@mui/icons-material";
import { Facebook, Twitter, LinkedIn } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { ccdquestions, investingquestions, returnsquestions, regulationsquestions, exitwindowquestions, learnquestions } from './questions';

const UpperPart = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "250px",
  backgroundColor: "rgba(20, 33, 43)",
  color: "rgb(20, 33, 43)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "2rem 1rem",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    minHeight: "200px",
    padding: "1.5rem 1rem",
  },
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: "600px",
  marginTop: "2rem",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    padding: "15px 45px 15px 45px",
    backgroundColor: "white",
    border: "1px solid rgba(20, 33, 43, 0.2)",
    borderRadius: "8px",
    color: "rgb(20, 33, 43)",
    fontSize: "1rem",
    transition: "all 0.3s ease",
    "&::placeholder": {
      color: "rgba(20, 33, 43, 0.6)",
    },
    "&:focus": {
      borderColor: "rgb(20, 33, 43)",
      boxShadow: "0 0 0 1px rgba(20, 33, 43, 0.1)",
    },
  },
}));

const StyledCard = styled(Card)({
  height: "100%",
  backgroundColor: "white",
  transition: "all 0.3s ease",
  border: "1px solid rgba(20, 33, 43, 0.1)",
  boxShadow: "0 4px 12px rgba(20, 33, 43, 0.08)",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 12px 28px rgba(20, 33, 43, 0.15)",
    border: "1px solid rgba(20, 33, 43, 0.2)",
  },
  "& .MuiCardContent-root": {
    padding: "1.5rem",
  },
  "& .icon-container": {
    color: "rgb(20, 33, 43)",
    marginBottom: "1rem",
  },
  "& .MuiTypography-h5": {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginBottom: "0.75rem",
    color: "rgb(20, 33, 43)",
  },
  "& .MuiTypography-body2": {
    color: "rgba(0, 0, 0, 0.7)",
  },
});

const topics = [
  {
    heading: "VENQ",
    paragraph: "Learn more about VENQ and how to get started",
    icon: <RocketLaunchOutlinedIcon fontSize="large" />,
    route: "/learnview",
  },
  {
    heading: "VENQ CCDs and Legality",
    paragraph: "Learn what's CCD and legal terms.",
    icon: <BusinessOutlinedIcon fontSize="large" />,
    route: "/ccd",
  },
  {
    heading: "Investing",
    paragraph: "All your questions about how to invest using VENQ, answered!",
    icon: <AssessmentOutlinedIcon fontSize="large" />,
    route: "/investing",
  },
  {
    heading: "Returns",
    paragraph: "Learn more about expected returns",
    icon: <AttachMoneyOutlinedIcon fontSize="large" />,
    route: "/returns",
  },
  {
    heading: "Exit windows",
    paragraph: "FAQs and informational articles about our property exit windows",
    icon: <RoofingOutlinedIcon fontSize="large" />,
    route: "/exitwindow",
  },
  {
    heading: "Regulations",
    paragraph: "Learn more about the regulatory environment we operate in",
    icon: <StarBorderOutlinedIcon fontSize="large" />,
    route: "/regulations",
  },
];

const Learn = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const allQuestions = {
    ccd: ccdquestions,
    investing: investingquestions,
    returns: returnsquestions,
    regulations: regulationsquestions,
    exitWindow: exitwindowquestions,
    learnview: learnquestions,
  };

  const handleSuggestionClick = useCallback((item) => {
    if (!item || !item.route) return;
    
    // Force lowercase route and remove any special characters
    const cleanRoute = item.route.toLowerCase().replace(/[^a-z0-9-/]/g, '');
    
    // Navigate with state
    navigate(cleanRoute, {
      state: {
        selectedQuestion: item.question,
        showOnlySelected: true,
        fromSearch: true,
        timestamp: Date.now() // Add timestamp to force state update
        
      }
    });
    
    // Clear search
    setSearchTerm('');
    setShowSuggestions(false);
  }, [navigate]);

  const getSuggestions = useCallback((value) => {
    if (!value || value.length < 2) return []; // Only show suggestions after 2 characters
    
    const suggestions = Object.entries(allQuestions)
      .flatMap(([section, questions]) =>
        questions.map(q => ({
          ...q,
          section,
          route: `/${section.toLowerCase()}`,
          id: encodeURIComponent(q.question)
        }))
      )
      .filter(q => q.question.toLowerCase().includes(value.toLowerCase()))
      .slice(0, 5);
      
    return suggestions;
  }, []);

  return (
    <Box sx={{ bgcolor: "rgba(20, 33, 43, 0.1)", minHeight: "100vh" }}>
      <UpperPart>
        <Box
          sx={{
            width: "100%",
            maxWidth: "610px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Header content */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              src="images/VENQ_BOLD_small1.png"
              alt="VENQ Logo"
              style={{
                height: isSmallScreen ? "24px" : "30px",
                objectFit: "contain",
              }}
            />
            <Typography
              onClick={() => navigate("/")}
              sx={{
                color: "rgb(20, 33, 43)",
                backgroundColor: "white",
                cursor: "pointer",
                fontWeight: 500,
                fontSize: "0.95rem",
                padding: "8px 20px",
                borderRadius: "6px",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  transform: "translateY(-1px)",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              Go to VENQ
            </Typography>
          </Box>

          <Typography
            variant="h1"
            sx={{
              fontSize: isSmallScreen ? "1.75rem" : "2.5rem",
              fontWeight: 600,
              mt: 4,
              mb: 3,
              color: "white",
              fontFamily: "Inter, sans-serif",
            }}
          >
            Advice and Answers from the VENQ Team
          </Typography>

          <Box sx={{ width: "100%", maxWidth: "600px", position: "relative" }}>
          <SearchIcon
                        sx={{
                          position: "absolute",
                          left: "16px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "rgb(20, 33, 43, 0.6)",
                          zIndex: 1,
                        }}
                      />
        <StyledInputBase
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => {
            const newValue = e.target.value;
            setSearchTerm(newValue);
            setShowSuggestions(newValue.length >= 2);
          }}
          onBlur={() => {
            // Delay hiding suggestions to allow for clicks
            setTimeout(() => setShowSuggestions(false), 200);
          }}
        />
        
        {showSuggestions && searchTerm.length >= 2 && (
          <Box 
            sx={{
              position: 'absolute',
              top: '100%',
              width: '100%',
              bgcolor: 'white',
              borderRadius: '8px',
              boxShadow: '0 4px 20px rgba(20, 33, 43, 0.1)',
              mt: 1,
              zIndex: 1000,
              maxHeight: '400px',
              overflowY: 'auto'
            }}
          >
            {getSuggestions(searchTerm).map((item, i) => (
              <Box
                key={i}
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent blur from hiding suggestions
                  handleSuggestionClick(item);
                }}
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  borderBottom: '1px solid rgba(20, 33, 43, 0.1)',
                  '&:hover': { 
                    bgcolor: 'rgba(20, 33, 43, 0.02)',
                    transform: 'translateY(-1px)',
                    transition: 'all 0.2s ease'
                  },
                  '&:last-child': {
                    borderBottom: 'none'
                  }
                }}
              >
                <Typography sx={{ 
                  color: 'rgb(20, 33, 43)',
                  fontWeight: 500,
                  mb: 0.5 
                }}>
                  {item.question}
                </Typography>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: 'rgba(20, 33, 43, 0.6)',
                    textTransform: 'capitalize'
                  }}
                >
                  {item.section.replace(/([A-Z])/g, ' $1').trim()}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
        </Box>
      </UpperPart>

      <Container
        maxWidth="lg"
        sx={{
          mt: isSmallScreen ? 4 : 6,
          mb: 8,
          px: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Grid container spacing={3}>
          {topics.map((topic, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Link to={topic.route} style={{ textDecoration: "none" }}>
                <StyledCard>
                  <CardContent>
                    <div className="icon-container">{topic.icon}</div>
                    <Typography variant="h5">{topic.heading}</Typography>
                    <Typography variant="body2">{topic.paragraph}</Typography>
                  </CardContent>
                </StyledCard>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          py: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: 3,
          }}
        >
          <img
            src="images/VENQ_BOLD_Big.png"
            alt="VENQ Logo"
            style={{
              width: "150px",
              height: "auto",
              margin: "0 auto",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <IconButton color="inherit" sx={{ mx: 1 }}>
            <Facebook />
          </IconButton>
          <IconButton color="inherit" sx={{ mx: 1 }}>
            <Twitter />
          </IconButton>
          <IconButton color="inherit" sx={{ mx: 1 }}>
            <LinkedIn />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Learn;
