// import axios from "axios";
// import { useNavigate, useLocation } from "react-router-dom";
// import config from "../../config";
// import "./Name.css";

// function Name() {
//   const params = new URLSearchParams(window.location.search);
//   const ref = params.get("ref");
//   const navigate = useNavigate();
//   const location = useLocation();
//   const otplessUser = location.state;
//   const URL = config.URL;

//   const data = {
//     name: "Aryan Pundir",
//     email: "pundiraryan0311@gmail.com",
//     phone: "9999999999",
//     isAdmin: false,
//     isVerified: 0,
//   };

//   const submitHandler = (e) => {
//     e.preventDefault();
//     console.log(e.target);
//     const formData = new FormData(e.target);
//     data.name = formData.get("name");

//     otplessUser.identities = otplessUser.identities.map((identity) => {
//       if (identity.identityType === "EMAIL") {
//         data.email = identity.identityValue;
//         identity.name = formData.get("name");
//       }
//       if (identity.identityType === "MOBILE") {
//         data.phone = identity.identityValue;
//       }
//       return identity;
//     });
    
//     // Pass the broker code as the ref query parameter
//     axios
//       .post(`${URL}/otpless/signup?ref=${encodeURIComponent(ref)}`, data)
//       .then((result) => {
//         console.log("post made");
//         if (result.data.moreinfoneeded) {
//           const sd = {
//             reqd: result.data.reqd,
//             tbs: result.data.tbs,
//           };
//           console.log(sd, "This is sd data");
//           navigate("/signup", { state: sd });
//         } else {
//           console.log(result.data.userinfo);
//           localStorage.setItem(
//             "userinfo",
//             JSON.stringify(result.data.userinfo)
//           );
//           navigate("/dashboard/properties");
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   return (
//     <div className="signup-name-container">
//       <form onSubmit={submitHandler}>
//         <label htmlFor="name">Hey, What's your name?</label>
//         <input
//           type="text"
//           name="name"
//           id="name"
//           placeholder="Type your name here..."
//         />
//         <button>Submit</button>
//       </form>
//     </div>
//   );
// }

// export default Name;
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../config";
import "./Name.css";
import video from "../OtplessLogin/7578552-hd_1920_1080_30fps.mp4";
function Name() {
  const navigate = useNavigate();
  const location = useLocation();
  const otplessUser = location.state;
  const URL = config.URL;

  const data = {
    name: "Aryan Pundir",
    email: "pundiraryan0311@gmail.com",
    phone: "9999999999",
    isAdmin: false,
    isVerified: 0,
    brokerDetails: null, // Add this to match schema
    referredBy: null
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      name: formData.get("name"),
      email: location.state.email, // Get from Clerk verification
      phone: location.state.phone, // Get from Clerk verification
      isAdmin: false,
      isVerified: 0,
      brokerDetails: null,
      referredBy: null
    };


    try {
      // Try to get broker code, but don't stop signup if none exists
      let brokerCode = null;
      try {
        console.log("Hellooo1");
        const brokerResponse = await axios.get(`${URL}/api/brokercode`);
        brokerCode = brokerResponse.data.brokerCode;
        console.log("HELLOOO2");
        console.log("Broker Code:", brokerCode);
      } catch (error) {
        console.log("No broker code available, continuing with signup");
      }

      // Sign up with or without broker code
      const signupUrl = brokerCode 
        ? `${URL}/otpless/signup?ref=${encodeURIComponent(brokerCode)}`
        : `${URL}/otpless/signup`;

      const signupResponse = await axios.post(signupUrl, data);

      console.log("post made");
      if (signupResponse.data.moreinfoneeded) {
        const sd = {
          reqd: signupResponse.data.reqd,
          tbs: signupResponse.data.tbs,
        };
        console.log(sd, "This is sd data");
        navigate("/signup", { state: sd });
      } else {
        console.log(signupResponse.data.userinfo);
        localStorage.setItem(
          "userinfo",
          JSON.stringify(signupResponse.data.userinfo)
        );
        navigate("/dashboard/properties");
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-white">
      {/* Left side - Video section */}
      <div className="w-full md:w-1/2 h-[40vh] md:h-screen flex items-center justify-center p-4 md:p-6">
        <div className="rounded-xl overflow-hidden w-full h-full max-h-[90vh]">
          <video 
            className="w-full h-full object-cover"
            autoPlay 
            muted 
            loop
            playsInline
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Right side container */}
      <div className="w-full md:w-1/2 min-h-[60vh] md:h-screen flex items-center justify-center">
        <div className="w-full max-w-[280px] h-[600px] flex items-center justify-center mx-auto">
          <div className="w-full">
            {/* Form container */}
            <form onSubmit={submitHandler} className="bg-white rounded-lg shadow-md p-6">
              <div className="text-center mb-8">
                <h2 className="text-2xl font-semibold text-gray-800">Hey there! 👋</h2>
                <p className="text-gray-600 mt-2">What's your name?</p>
              </div>
              
              <div className="mb-6">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Type your name here..."
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-purple-500 focus:ring-2 focus:ring-purple-500 outline-none transition duration-200"
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full bg-purple-600 text-white py-3 rounded-lg font-semibold hover:bg-purple-700 transition duration-200"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Name;