import React, { useRef, useEffect } from 'react';

const OTPInputLogin = ({ value, onChange, loading }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (index > 0 && !e.target.value) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (newValue.match(/^[0-9]$/)) {
      const newOTP = value.split('');
      newOTP[index] = newValue;
      onChange(newOTP.join(''));
      
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 6);
    if (pastedData.match(/^[0-9]+$/)) {
      onChange(pastedData.padEnd(6, ''));
    }
  };

  return (
    <div className="flex gap-2 mb-4">
      {Array(6).fill(0).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          ref={el => inputRefs.current[index] = el}
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          disabled={loading}
          className="w-10 h-10 text-center border-b-2 border-[#00D09C] 
            bg-transparent text-[#44475b] focus:outline-none disabled:opacity-50
            [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none 
            [&::-webkit-inner-spin-button]:appearance-none"
        />
      ))}
    </div>
  );
};

export default OTPInputLogin;
