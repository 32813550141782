import { useEffect } from 'react';
import { useSignUp } from "@clerk/clerk-react";

const Auth_Callback = () => {
  const { signUp, isLoaded } = useSignUp();

  useEffect(() => {
    const handleCallback = async () => {
      if (!signUp || !isLoaded) return;

      try {
        const params = new URLSearchParams(window.location.search);
        const status = params.get('__clerk_status');

        if (status === 'verified') {
          const emailAddress = signUp.emailAddress;
          if (emailAddress) {
            localStorage.setItem('verifiedEmail', emailAddress);
            window.opener.postMessage({ type: 'EMAIL_VERIFIED', email: emailAddress }, '*');
          }
        }
        window.close();
      } catch (err) {
        console.error('OAuth callback error:', err);
        window.close();
      }
    };

    handleCallback();
  }, [signUp, isLoaded]);

  return null;
};

export default Auth_Callback;
