import React, { useState } from 'react';
import { FaCalendarAlt, FaPlus, FaMinus } from 'react-icons/fa';

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "How does VenQ stand out in real estate investing?",
            answer: "VenQ distinguishes itself by being India's first platform enabling real estate investments with a low entry of ₹5,000."
        },
        {
            question: "What property options are on VenQ, and can I choose my investments?",
            answer: "VenQ offers various property options, including residential, plots, and rental-focused properties. You have the freedom to choose investments that align with your preferences."
        },
        {
            question: "What is the minimum investment to start on VenQ?",
            answer: "The minimum investment to start on VenQ is just ₹5,000, making real estate accessible to a broader audience."
        },
        {
            question: "How does VenQ ensure investment security and transparency?",
            answer: "VenQ ensures investment security and transparency through Compulsory Convertible Debentures (CCDs), aligning investor interests with the property's success."
        },
        {
            question: "Can I sell or exit my investment before property sale on VenQ?",
            answer: "Yes, you can sell or exit your investment before the property sale by listing and trading your CCDs on the VenQ platform, providing flexibility and liquidity."
        },
    ];

    return (
        <>
            {/* Desktop View */}
            <div className='w-full hidden h-screen md:flex justify-center items-center'>
                <div className="w-[90%] h-auto max-h-[90vh] bg-black rounded-3xl py-8 overflow-y-auto">
                    <div className="flex font-raleway w-full text-white items-center flex-col">
                        <h1 className='px-4 py-2 font-semibold justify-center items-center gap-3 flex text-[#2ab589] rounded-xl'
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                boxShadow: '0 4px 20px rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(5px)',
                            }}>
                            <span className='text-[#2ab589]'><FaCalendarAlt /></span>
                            <span className='text-[#2ab589]'>Have a question?</span>
                        </h1>
                        <h1 className='md:text-[40px] 2xl:text-[50px] leading-[60px] text-center font-raleway mt-5'>
                            We have answers ?
                        </h1>
                        <div className="w-full font-raleway mt-8 flex flex-col items-center gap-4 px-6">
                            {faqData.map((item, index) => (
                                <div
                                    key={index}
                                    className="w-full 2xl:w-[40vw] md:w-[50vw] p-5 rounded-2xl flex flex-col justify-center items-start bg-[#2d2b2b] relative shadow-lg"
                                >
                                    <div
                                        onClick={() => toggleAnswer(index)}
                                        className="flex justify-between w-full cursor-pointer items-center"
                                    >
                                        <h1 className="2xl:text-base md:text-sm font-medium text-white pr-8">{item.question}</h1>
                                        <span className="text-white text-2xl flex-shrink-0">
                                            {openIndex === index ? <FaMinus size={16} /> : <FaPlus size={16} />}
                                        </span>
                                    </div>
                                    {openIndex === index && (
                                        <p className="text-left font-medium text-zinc-400 mt-5 text-[15px]">
                                            {item.answer}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile View */}
            <div className="w-full md:hidden mt-[1.8rem]">
                <div className="w-[90%] mx-auto bg-black rounded-3xl py-6">
                    <div className="flex font-raleway w-full text-white items-center flex-col">
                        {/* Added Have a question section identical to desktop */}
                        <h1 className='px-4 py-2 font-semibold justify-center items-center gap-3 flex text-[#2ab589] rounded-xl'
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                boxShadow: '0 4px 20px rgba(255, 255, 255, 0.2)',
                                backdropFilter: 'blur(5px)',
                            }}>
                            <span className='text-[#2ab589]'><FaCalendarAlt /></span>
                            <span className='text-[#2ab589]'>Have a question?</span>
                        </h1>
                        <h1 className="text-[24px] leading-[50px] text-center font-raleway mt-5">
                            We have answers ?
                        </h1>
                        <div className="w-full font-raleway mt-6 flex flex-col items-center gap-4 px-6">
                            {faqData.map((item, index) => (
                                <div
                                    key={index}
                                    className="w-full p-5 rounded-2xl flex flex-col justify-center items-start bg-[#2d2b2b] relative shadow-lg"
                                >
                                    <div
                                        onClick={() => toggleAnswer(index)}
                                        className="flex justify-between w-full cursor-pointer items-center"
                                    >
                                        <h1 className="text-[12px] font-medium text-white pr-8">
                                            {item.question}
                                        </h1>
                                        <span className="text-white text-xl flex-shrink-0">
                                            {openIndex === index ? <FaMinus size={16} /> : <FaPlus size={16} />}
                                        </span>
                                    </div>
                                    {openIndex === index && (
                                        <p className="text-left font-medium text-zinc-400 mt-5 text-[12px]">
                                            {item.answer}
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FAQ;