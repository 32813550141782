export const learnquestions = [
    {
      question: "What is VENQ?",
      answer:
        "VENQ is a groundbreaking real estate investment platform that allows individuals to invest in prime properties with just Rs.50,000. We specialize in fractionalized investing, enabling users to own a share of lucrative real estate opportunities.",
    },
    {
      question: "Who can invest in VENQ?",
      answer:
        "VENQ is open to all Indian residents above 18 years of age. Whether you're a seasoned investor or a first-timer, our platform is designed to cater to a diverse range of users.",
    },
    {
      question: "How do I get started?",
      answer:
        "Getting started with VENQ is simple! Sign up on our platform, and explore a curated selection of real estate listings. Choose the property you wish to invest in and become a fractional owner with just a few clicks. And E-signing a few documents which takes less than 3 minutes.",
    },
    {
      question: "How does it work?",
      answer:
        "VENQ employs a sophisticated process to make fractionalized investing accessible and secure. Special Purpose Vehicle (SPV), Ownership Structure, Management Contract, Voting Rights.",
    },
    {
      question: "What are VENQ's services?",
      answer:
        "VENQ offers a comprehensive platform for fractionalized investing in real estate. Our services encompass meticulous property selection, expert investment management, and a streamlined investment experience. Backed by tangible assets, VENQ ensures a secure and rewarding journey into the realm of real estate ownership.",
    },
    {
      question: "Is VENQ a long-term investment?",
      answer:
        "Yes, VENQ is designed for both short-term and long-term investors. Users can choose their investment horizon based on their financial goals. And we suggest a holding period of 3-5 years.",
    },
    {
      question: "What are VENQ's fees?",
      answer:
        "At VENQ, we believe in transparency and aligning our success with yours. Here's an overview of our fee structure: VENQ Fees (3%), Transaction Costs, Performance Fees (10%). Our commitment to transparency ensures that investors have a clear understanding of the associated costs, empowering you to make informed decisions about your investments with VENQ.",
    },
    {
      question: "What are transaction costs?",
      answer:
        "Transaction costs cover charges related to property acquisition, legal processes, and administrative overheads. These costs are proportionately distributed among investors.",
    },
    {
      question: "How are properties selected on VENQ?",
      answer:
        "Properties listed on VENQ undergo a meticulous selection process, with our team meticulously evaluating factors such as location, market trends, and growth potential. This ensures a diverse and promising portfolio, aligning with our commitment to providing investors with high-quality real estate opportunities.",
    },
    {
      question: "What is a proof of address document (POA)?",
      answer:
        "A proof of address document (POA) is a document that verifies your residential address. It can be an Aadhar card, a passport, or any government-issued document that confirms your place of residence. This is a standard requirement for user verification and compliance purposes.",
    },
    // Add the new questions and answers here
  ];

  export const ccdquestions = [
    {
      question: "What are CCDs (Convertible Debentures)?",
      answer:
        "Convertible Debentures (CCDs) are financial instruments that can be converted into equity shares at a later date. VENQ issues CCDs as a way for investors to participate in the potential returns of a real estate investment.",
    },
    {
      question: "How does the issuance of CCDs work on VENQ?",
      answer:
        "Upon a successful fundraise, VENQ initiates a private placement offer for CCDs to those who invested. The issuance follows the rules and regulations outlined in the Companies Act 2013.",
    },
    {
      question: "What legal protections do CCDs provide to investors?",
      answer:
        "CCD holders have the right to convert their debentures into equity shares, thereby becoming shareholders in the underlying real estate asset. Legal provisions within the issuance agreement protect the rights of CCD holders.",
    },
    {
      question:
        "Is VENQ regulated by SEBI (Securities and Exchange Board of India)?",
      answer:
        "VENQ issues CCDs through private placement, targeting a select group of investors. As this falls outside the public offering domain, SEBI regulations related to public issues do not apply directly. However, we operate within the broader framework of financial regulations.",
    },
    {
      question:
        "How are CCDs different from traditional real estate investments?",
      answer:
        "CCDs offer a unique investment avenue by providing a stake in a real estate asset without the need for full ownership. Investors benefit from potential appreciation while having the flexibility to convert their debentures into equity shares at the date of sale.",
    },
    {
      question:
        "What happens if an investor wants to exit their investment before conversion?",
      answer:
        "Investors looking to exit their investment before the conversion period ends may explore secondary market options if available. VENQ is actively working on introducing a secondary market to facilitate such transactions.",
    },
    {
      question: "What legal documentation is involved in CCD issuance?",
      answer:
        "CCD issuance is governed by legal agreements, including the agreement to sell, private placement offer, and other relevant documents. These agreements outline the terms, rights, and obligations of the parties involved.",
    },
    {
      question:
        "How does VENQ ensure compliance with company laws during CCD issuance?",
      answer:
        "VENQ adheres to the provisions of the Companies Act 2013 during the issuance of CCDs, ensuring legal compliance and transparency in the process. Our legal team oversees the entire process to safeguard the interests of both VENQ and investors.",
    },
  ];

  export const investingquestions = [
    {
      question: "How much can I invest?",
      answer:
        "VENQ allows investors to start with a minimum investment of Rs. 50,000, making real estate investment accessible to a broader range of individuals.",
    },
    {
      question: "Why invest in Real Estate?",
      answer:
        "Real estate has historically been a stable and appreciating asset class. Investing in real estate through VENQ provides an opportunity to diversify your investment portfolio and gain exposure to the real estate market.",
    },
    {
      question: "What happens if not enough funds are raised?",
      answer:
        "If the target funds are not raised within the specified timeframe, the investment campaign may be canceled, and the invested amount will be refunded to the investors. And VENQ's commission will be forfeited for the exact amount that you had invested for your next investment.",
    },
    {
      question: "What am I investing in?",
      answer:
        "Investors on VENQ are investing in a specific real estate asset listed on the platform. Each campaign clearly outlines the details of the property, its location, and the investment terms.",
    },
    {
      question: "What is a Special Purpose Vehicle (SPV)?",
      answer:
        "An SPV is a legal entity created for a specific purpose, often to hold and manage a particular real estate asset. In VENQ's context, an SPV is formed to facilitate the fractionalized ownership structure.",
    },
    {
      question: "How many shares will I own?",
      answer:
        "The number of shares an investor owns is proportionate to their investment amount. VENQ issues shares in the form of Convertible Debentures (CCDs), and the conversion ratio is specified in the campaign details.",
    },
    {
      question: "What if I need to sell my stake before the investment term?",
      answer:
        "VENQ is actively working on introducing a secondary market, allowing investors to sell their stakes before the investment term concludes, providing liquidity to investors.",
    },
    {
      question: "What happens at the end of the Investment Term?",
      answer:
        "At the end of the Investment Term, investors have the option to exit or continue their ownership, subject to the terms specified in the investment campaign.",
    },
    {
      question: "Who manages the property? Can I visit?",
      answer:
        "The property is typically managed by a professional property management team. While direct visits may not always be possible, investors will receive regular updates on the property's performance.",
    },
    {
      question: "When will I receive my documents?",
      answer:
        "Upon a successful fundraise, investors will receive their ownership documents, including CCD certificates, within a reasonable timeframe.",
    },
    {
      question: "How can I verify my ownership documents?",
      answer:
        "VENQ ensures transparency, and investors can verify their ownership documents by consulting the details provided in the CCD certificates and associated legal documents.",
    },
  ];

  export const exitwindowquestions = [
    {
      question: "What is an Exit Window?",
      answer:
        "An Exit Window is a predetermined period which happens quarterly, during which investors can choose to exit their investment in a property campaign. It provides flexibility for investors to sell their shares and realize returns within a specified timeframe.",
    },
    {
      question: "What is a share price?",
      answer:
        "The share price represents the value assigned to each share in a property campaign. It is determined by factors such as property appreciation, potential rental income, and market conditions. Share prices are transparently communicated to investors.",
    },
    {
      question: "How is the price of shares determined?",
      answer:
        "The price of shares is determined by various factors such as the property's current valuation, discount offered, supply of shares, etc. When purchasing a property during an exit window you will be able to see various available share prices, each depending on the discount offered by the seller. When selling your shares you will be able to see the share price you are selling at, and learn more about how that share price was calculated.",
    },
    {
      question: "Are Exit Windows the only way I can exit my investment?",
      answer:
        "While Exit windows are one of the exit strategies we offer, you also have the option to exit your investment through a governance vote. In a governance vote, if we find a buyer for your asset, the community will vote on whether to sell the asset or not. If the vote is in favor of selling, your full asset will be sold, and you will receive your portion of the proceeds based on your ownership share.",
    },
  ];

  export const regulationsquestions = [
    {
      question:
        "How does VENQ comply with the Real Estate (Regulation and Development) Act, 2016 (RERA)?",
      answer:
        "VENQ facilitates fundraising for real estate properties and does not engage in property development. As a result, VENQ operates outside the scope of RERA, which primarily regulates developers and real estate projects.",
    },
    {
      question:
        "Are there any restrictions on international investors participating in VENQ?",
      answer:
        "International investors, including Non-Resident Indians (NRIs), are welcome to participate in VENQ. However, the investment structure and regulatory requirements may vary for international investors, and compliance with the Foreign Exchange Management Act (FEMA) is crucial.",
    },
    {
      question:
        "How does VENQ handle compliance with the Securities and Exchange Board of India (SEBI)?",
      answer:
        "VENQ issues convertible debentures through private placements, catering to a select group of investors. This approach aligns with SEBI provisions, as the offering is not made to the public, and the debentures are converted into equity shares.",
    },
    {
      question:
        "What steps does VENQ take to ensure data protection and privacy?",
      answer:
        "VENQ prioritizes the confidentiality and security of user data. Stringent data protection measures, including encryption and secure storage, are implemented to comply with applicable data protection laws.",
    },
    {
      question:
        "Does VENQ adhere to the guidelines set by the Reserve Bank of India (RBI)?",
      answer:
        "VENQ follows regulations set by the RBI, especially in cases where investments involve Non-Resident Indians (NRIs). Compliance with FEMA regulations is crucial for transactions with international investors.",
    },
    {
      question: "Why am I asked for personal details and documents?",
      answer:
        "Collecting personal details and documents is a regulatory requirement aimed at ensuring compliance with anti-money laundering (AML) and know your customer (KYC) regulations. It helps verify the identity of investors and protect against potential fraudulent activities.",
    },
    {
      question:
        "If VENQ were to go bankrupt, what are the measures in place to protect my investments?",
      answer:
        "VENQ operates within a legal framework to safeguard investor interests. In the event of bankruptcy, the assets held in Special Purpose Vehicles (SPVs) are legally distinct and protected, providing a level of insulation for investors' holdings.",
    },
    {
      question:
        "Must I update VENQ if my personal details or circumstances change?",
      answer:
        "Yes, it is essential to keep your personal details updated with VENQ. Changes in circumstances, such as address or contact information, should be promptly communicated to ensure accurate records and compliance with regulatory requirements.",
    },
    {
      question: "What is a Professional Client?",
      answer:
        "A Professional Client is a category of investor defined by regulatory authorities. These investors typically have a higher level of financial knowledge and experience. Certain regulatory exemptions and opportunities may be available to Professional Clients.",
    },
    {
      question: "Are taxes applicable to investments at VENQ?",
      answer:
        "Yes, taxes may be applicable to investment returns. The tax implications can vary based on individual circumstances and jurisdictions. It is recommended to seek advice from a tax professional to understand the specific tax obligations related to your investments.",
    },
  ];


  export const returnsquestions = [
    {
      question: "How do returns work?",
      answer:
        "Returns are generated through the appreciation of the property's value and potential rental income. Upon a successful exit or completion of the Investment Term, investors receive returns proportionate to their ownership.",
    },
    {
      question: "How will I earn my return on VENQ?",
      answer:
        "Returns are earned through the increase in the property's value and any rental income generated. Upon the sale of the property or at the end of the Investment Term, investors realize returns on their stake.",
    },
    {
      question: "What returns can I expect?",
      answer:
        "Expected returns vary depending on the specific property and market conditions. Each investment campaign outlines the projected returns, providing transparency to investors.",
    },
    {
      question: "How will I know what my investment is worth?",
      answer:
        "The value of your investment is reflected in the appreciation of the property and any potential rental income. Regular updates and performance reports are provided to keep investors informed about the status of their investment. You can also use your portfolio to track your investments.",
    },
    {
      question: "Do I earn any interest on the available balance in my wallet?",
      answer:
        "VENQ does not offer interest on the wallet balance. The wallet is primarily used for the convenience of investors in managing their transactions on the platform.",
    },
    {
      question: "What are Material Changes?",
      answer:
        "Material Changes refer to significant alterations or updates related to an ongoing investment campaign. These could include changes in project details, investment terms, or other crucial information. Investors are promptly informed about Material Changes, and their consent is sought before proceeding.",
    },
  ];