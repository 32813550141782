// import React, { useEffect } from "react";
// import video from './VENQ_signup.mp4'
// import { loadOTPlessScriptSignIn, unloadOTPlessScript } from "../../utils/otpless";
// import { useNavigate } from "react-router-dom";
// import config from "../../config";
// import axios from "axios";
// import "./OtplessLogin.css"

// export default function OtplessLogin() {
//   const navigate = useNavigate();
//   const URL = config.URL;

//   useEffect(() => {
//     loadOTPlessScriptSignIn();
//     window.otpless = (otplessUser) => {
//       console.log(otplessUser);
//       axios
//         .post(`${URL}/otpless/login`, otplessUser)
//         .then((result) => {
//           console.log("post made");
//           if (result.data.moreinfoneeded) {
//             const sd = {
//               reqd: result.data.reqd,
//               tbs: result.data.tbs,
//             };
//             console.log(sd);
//             window.alert("User not available, Please Signup");
//             navigate("/", { state: sd });
//           } else {
//             console.log(result.data.userinfo);
//             localStorage.setItem("userinfo", JSON.stringify(result.data.userinfo));
//             navigate("/dashboard/properties");
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//     };
//     return () => {
//       unloadOTPlessScript();
//     };
//   }, [navigate, URL]);
//   return (
//     <div className="flex flex-col md:flex-row h-screen bg-white">
//       {/* Left side - Video section */}
//       <div className="w-full md:w-1/2 h-[40vh] md:h-screen flex items-center justify-center p-4 md:p-6">
//         <div className="rounded-xl overflow-hidden w-full h-full max-h-[90vh]">
//           <video 
//             className="w-full h-full object-cover"
//             autoPlay 
//             muted 
//             loop
//             playsInline
//           >
//             <source src={video} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       </div>

//       {/* Right side container */}
//       <div className="w-full md:w-1/2 min-h-[60vh] md:h-screen flex items-center justify-center">
//         {/* Form + Link container with fixed height and centered */}
//         <div className="w-full max-w-[280px] h-[600px] flex items-center justify-center mx-auto">
//           <div id="otpless-login-page" className="relative">
//             {/* Redirect link positioned absolutely */}
//             <div className="absolute left-0 right-0 -bottom-8 sm:-bottom-10 md:-bottom-12 text-center">
//               <p className="text-sm md:text-base text-gray-600">
//                 New to VENQ? <a href="/" className="text-purple-600 hover:text-purple-700 font-medium">
//                   Sign up now
//                 </a> to get started!
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// import React, { useState } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import { useSignIn, useSignUp } from "@clerk/clerk-react";
// import { Loader, X, ArrowLeft } from 'lucide-react';
// import GoogleIcon from '@mui/icons-material/Google';
// import OTPInputLogin from "./OTPInputLogin";
// import video from './VENQ_signup.mp4';
// import axios from 'axios';
// import config from "../../config";

// const Login = () => {
//   const navigate = useNavigate();
//   const { signIn, isLoaded, setActive } = useSignIn();
//   const { signUp } = useSignUp();
  
//   const [identifier, setIdentifier] = useState("");
//   const [showVerification, setShowVerification] = useState(false);
//   const [otpValue, setOtpValue] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [socialLoading, setSocialLoading] = useState("");
//   const [error, setError] = useState("");

//   const handleContinue = async (e) => {
//     e.preventDefault();
//     setError(""); // Clear any previous errors
//     try {
//       setLoading(true);
//       setError("");

//       const isEmail = identifier.includes('@');
//       const formattedPhone = !isEmail && !identifier.startsWith("+91") 
//         ? `+91${identifier}` 
//         : identifier;

//       // Send OTP using Clerk
//       if (isEmail) {
//         await signUp.create({
//           emailAddress: identifier,
//           strategy: "email_code",
//         });
//       } else {
//         await signUp.create({
//           phoneNumber: formattedPhone,
//           strategy: "phone_code",
//         });
//       }

//       setShowVerification(true);
//     } catch (err) {
//       console.error(err);
//       setError(err.message || "Failed to send verification code");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSubmit = async () => {
//     if (otpValue.length !== 6) return;

//     try {
//       setLoading(true);
//       setError("");

//       const isEmail = identifier.includes('@');
//       let result;
//       console.log("Hello");
//       console.log(isEmail);
//       if (isEmail) {
//         result = await signUp.attemptEmailAddressVerification({
//           code: otpValue,
//         });
//         console.log(result);
//         console.log("shdbfh");
//       } else {
//         result = await signUp.attemptPhoneNumberVerification({
//           code: otpValue,
//         });
//       }
//       if (result?.emailAddress || result?.phoneNumber) {

//         // Check if user exists in the database
//         const verifyResponse = await axios.post(`${config.URL}/api/auth/login/verify-user`, {
//           identifier: isEmail ? identifier : identifier.startsWith("+91") ? identifier : `+91${identifier}`,
//           type: isEmail ? 'email' : 'phone'
//         });
//         console.log(verifyResponse);
//         console.log("jsdbnf");

//         if (verifyResponse.data.success) {
//           // User exists, proceed with login
//           const userResponse = await axios.post(`${config.URL}/api/auth/login/get-user`, {
//             identifier: isEmail ? identifier : identifier.startsWith("+91") ? identifier : `+91${identifier}`
//           });

//           localStorage.setItem("userinfo", JSON.stringify(userResponse.data.userinfo));
//           await setActive({ session: result.createdSessionId });
//           navigate("/dashboard/properties");
//         } else {
//           // User doesn't exist, show error and suggest signup
//           setError('No account found. Please sign up first.');
//           setShowVerification(false);
//         }
//       }
//     } catch (err) {
//       console.error(err);
//       setError("No account found");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSocialSignIn = async (provider) => {
//     try {
//       setSocialLoading(provider);
//       setError(""); // Clear any previous errors

//       const response = await signUp.create({
//         strategy: `oauth_${provider}`,
//         redirectUrl: `${window.location.origin}/auth-callback`,
//         redirectUrlComplete: `${window.location.origin}/auth-callback`,
//       });

//       const verificationURL = response.verifications?.externalAccount?.externalVerificationRedirectURL;

//       if (!verificationURL) {
//         throw new Error('No verification URL received');
//       }

//       const authWindow = window.open(
//         verificationURL,
//         '_blank',
//         'width=600,height=700,left=300,top=100,popup=true'
//       );

//       if (authWindow) {
//         const checkAuth = setInterval(async () => {
//           if (authWindow.closed) {
//             clearInterval(checkAuth);
//             setIdentifier("");
//             setOtpValue("");
//             return;
//           }

//           try {
//             await signUp.reload();
//             if (signUp.verifications?.externalAccount?.status === "verified") {
//               clearInterval(checkAuth);
//               const emailAddress = signUp.emailAddress;
//               if (emailAddress) {
//                 // Check if user exists in the database
//                 const verifyResponse = await axios.post(`${config.URL}/auth/login/verify-user`, {
//                   identifier: emailAddress,
//                   type: 'email'
//                 });

//                 if (verifyResponse.data.success) {
//                   // User exists, proceed with login
//                   const userResponse = await axios.post(`${config.URL}/auth/login/get-user`, {
//                     identifier: emailAddress
//                   });

//                   localStorage.setItem("userinfo", JSON.stringify(userResponse.data.userinfo));
//                   await setActive({ session: signUp.createdSessionId });
//                   authWindow.close();
//                   navigate("/dashboard/properties");
//                 } else {
//                   // User doesn't exist, show error and suggest signup
//                   setError('No account found with this Google account. Please sign up first.');
//                   authWindow.close();
//                 }
//               }
//             }
//           } catch (err) {
//             console.error("Error checking auth status:", err);
//             setError('No account found with this Google account');
//             authWindow.close();
//           }
//         }, 1000);
//       }
//     } catch (err) {
//       console.error('Social auth error:', err);
//       setError('Authentication failed. Please try again.');
//     } finally {
//       setSocialLoading('');
//     }
//   };

//   if (!isLoaded) {
//     return (
//       <div className="flex items-center justify-center min-h-screen">
//         <Loader className="w-6 h-6 animate-spin" />
//       </div>
//     );
//   }

//   return (
//     <div className="flex flex-col lg:flex-row h-screen lg:h-[420px] overflow-hidden">
//       <div className="hidden lg:block w-full lg:w-1/2 h-[330px] lg:h-full relative">
//         <video
//           className="absolute inset-0 w-full h-full object-cover"
//           autoPlay
//           muted
//           loop
//           playsInline
//         >
//           <source src={video} type="video/mp4" />
//         </video>
//       </div>

//       <div className="w-full lg:w-1/2 flex items-center justify-center bg-white overflow-hidden">
//         <div className="w-full max-w-[320px] px-6 py-6 lg:py-0">
//           {!showVerification ? (
//             // Initial Login Form
//             <>
//               <h1 className="text-[28px] font-medium text-[#44475b] text-center mb-6">
//                 Welcome to VENQ
//               </h1>

//               <button
//                 onClick={() => handleSocialSignIn("google")}
//                 disabled={loading || !!socialLoading}
//                 className="w-full flex items-center justify-center gap-3 px-5 py-2.5
//                   text-[14px] font-medium text-[#44475b] bg-white rounded-lg
//                   shadow-[0_2px_6px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_12px_rgba(0,0,0,0.12)]
//                   transition-shadow duration-300 disabled:opacity-50"
//               >
//                 {socialLoading === "google" ? (
//                   <Loader className="w-4 h-4 animate-spin" />
//                 ) : (
//                   <>
//                     <GoogleIcon className="w-5 h-5" />
//                     Continue with Google
//                   </>
//                 )}
//               </button>

//               <div className="relative my-6">
//                 <div className="absolute inset-0 flex items-center">
//                   <div className="w-full border-t border-[#e5e5e5]"></div>
//                 </div>
//                 <div className="relative flex justify-center text-sm">
//                   <span className="px-4 text-[#7c7e8c] bg-white">Or</span>
//                 </div>
//               </div>

//               {error && (
//                 <div className="mb-4 p-2.5 text-sm text-red-600 bg-red-50 rounded-lg border border-red-100">
//                   {error}
//                 </div>
//               )}

//               <div className="space-y-5">
//                 <form onSubmit={handleContinue}>
//                   <div className="space-y-4">
//                     <div className="relative">
//                       <input
//                         type="text"
//                         className="w-full pb-2 text-[14px] text-[#44475b] bg-transparent 
//                           border-b-2 border-[#00D09C] focus:outline-none focus:border-[#00D09C]
//                           placeholder:text-[#7c7e8c]"
//                         value={identifier}
//                         onChange={(e) => setIdentifier(e.target.value)}
//                         placeholder="Your Email Address or Phone Number"
//                         required
//                       />
//                     </div>

//                     <button
//                       type="submit"
//                       className="w-full py-2.5 text-[14px] font-medium text-white 
//                         bg-[#00D09C] hover:bg-[#00b386] rounded-lg transition-colors 
//                         disabled:opacity-50"
//                       disabled={loading}
//                     >
//                       {loading ? (
//                         <Loader className="w-4 h-4 animate-spin mx-auto" />
//                       ) : (
//                         "Continue"
//                       )}
//                     </button>
//                   </div>
//                 </form>

//                 <div className="text-center text-[13px] text-[#7c7e8c]">
//                   New to VENQ?{" "}
//                   <Link
//                     to="/"
//                     className="text-[#00D09C] hover:text-[#00b386] font-medium"
//                   >
//                     Sign up now
//                   </Link>
//                 </div>
//               </div>
//             </>
//           ) : (
//             // OTP Verification Form
//             <div className="animate-fadeIn">
//               <div className="flex items-center mb-6">
//                 <button
//                   onClick={() => {
//                     setShowVerification(false);
//                     setOtpValue("");
//                     setError("");
//                   }}
//                   className="p-2 hover:bg-gray-100 rounded-full transition-colors"
//                 >
//                   <ArrowLeft className="w-5 h-5 text-[#44475b]" />
//                 </button>
//                 <h2 className="text-2xl font-semibold text-[#44475b] ml-2">
//                   Enter OTP
//                 </h2>
//               </div>

//               {error && (
//                 <div className="mb-4 p-2.5 text-sm text-red-600 bg-red-50 rounded-lg border border-red-100">
//                   {error}
//                 </div>
//               )}

//               <div className="space-y-6">
//                 <div className="space-y-1">
//                   <label className="text-sm font-medium text-[#00D09C]">
//                     {identifier.includes('@') ? 'Your Email Address' : 'Your Phone Number'}
//                   </label>
//                   <div className="border-b border-gray-200 pb-2">
//                     <span className="text-[#44475b]">{identifier}</span>
//                   </div>
//                 </div>

//                 <div className="space-y-2">
//                   <OTPInputLogin
//                     value={otpValue}
//                     onChange={setOtpValue}
//                     loading={loading}
//                   />
//                   <p className="text-xs text-gray-500">
//                     Enter the 6 digit OTP sent to {identifier.includes('@') ? 'your email' : 'your phone'}
//                   </p>
//                 </div>

//                 <button
//                   onClick={handleSubmit}
//                   disabled={otpValue.length !== 6 || loading}
//                   className="w-full py-3 text-sm font-medium text-white 
//                     bg-[#00D09C] hover:bg-[#00b386] rounded-lg transition-colors 
//                     disabled:opacity-50 disabled:cursor-not-allowed"
//                 >
//                   {loading ? (
//                     <Loader className="w-4 h-4 animate-spin mx-auto" />
//                   ) : (
//                     "Submit"
//                   )}
//                 </button>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;


// import React, { useState, useEffect } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import { Loader, ArrowLeft } from 'lucide-react';
// import GoogleIcon from '@mui/icons-material/Google';
// import OTPInputLogin from "./OTPInputLogin";
// import video from './VENQ_signup.mp4';
// import axios from 'axios';
// import config from "../../config";
// import { useSignIn, useSignUp } from "@clerk/clerk-react";

// const Login = () => {
//   const navigate = useNavigate();
//   const [identifier, setIdentifier] = useState("");
//   const [showVerification, setShowVerification] = useState(false);
//   const [otpValue, setOtpValue] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [socialLoading, setSocialLoading] = useState("");
//   const [email, setEmail] = useState("");
//   const [error, setError] = useState("");
//   const { signIn, isLoaded, setActive } = useSignIn();
//   const {signUp} =useSignUp()
//   const [scriptLoaded, setScriptLoaded] = useState(false);
//   const API_KEY = process.env.REACT_APP_PHONE_EMAIL_API_KEY;
//   const CLIENT_ID = process.env.REACT_APP_PHONE_EMAIL_CLIENT_ID;

//   useEffect(() => {
//     // Add global receiver function
//     window.phoneEmailReceiver = async (userObj) => {
//       try {
//         if (!userObj || !userObj.user_json_url) {
//           throw new Error('Invalid user object received');
//         }
//         console.log("Received user_json_url:", userObj.user_json_url);
//         setShowVerification(true);
//       } catch (err) {
//         console.error("Error in phoneEmailReceiver:", err);
//         setError('Failed to receive OTP. Please try again.');
//       }
//     };

//     return () => {
//       delete window.phoneEmailReceiver;
//     };
//   }, []);

//   const handleContinue = async (e) => {
//     e.preventDefault();
//     setError("");
//     setLoading(true);

//     try {
//       // Remove any existing verification divs
//       const existingDivs = document.querySelectorAll('.pe_verify_email');
//       existingDivs.forEach(div => div.remove());

//       // Create verification container
//       const container = document.createElement('div');
//       container.id = 'verification-container';
//       container.style.position = 'fixed';
//       container.style.top = '0';
//       container.style.left = '0';
//       container.style.width = '100%';
//       container.style.height = '100%';
//       container.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
//       container.style.display = 'flex';
//       container.style.justifyContent = 'center';
//       container.style.alignItems = 'center';
//       container.style.zIndex = '9999';

//       // Create verification div
//       const verificationDiv = document.createElement('div');
//       verificationDiv.className = 'pe_verify_email';
//       verificationDiv.setAttribute('data-client-id', CLIENT_ID);
//       verificationDiv.setAttribute('data-email', email);
      
//       // Add verification div to container
//       container.appendChild(verificationDiv);
//       document.body.appendChild(container);

//       // Add click handler to close container when clicking outside
//       container.addEventListener('click', (event) => {
//         if (event.target === container) {
//           document.body.removeChild(container);
//         }
//       });

//       // Create a script element
//       const script = document.createElement('script');
//       script.src = 'https://www.phone.email/verify_email_v1.js';
//       script.async = true;

//       // Add the script to the document
//       document.head.appendChild(script);

//       // Wait for script to load
//       await new Promise((resolve, reject) => {
//         script.onload = resolve;
//         script.onerror = reject;
//       });

//       console.log('Script loaded successfully');

//     } catch (err) {
//       console.error("Error in handleContinue:", err);
//       setError(err.message || "Failed to send verification code");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSubmit = async () => {
//     if (otpValue.length !== 6) return;

//     setLoading(true);
//     setError("");

//     try {
//       // Check if user exists in the database
//       const verifyResponse = await axios.post(`${config.URL}/api/auth/login/verify-user`, {
//         identifier: email,
//         type: 'email'
//       });

//       if (verifyResponse.data.success) {
//         // User exists, proceed with login
//         const userResponse = await axios.post(`${config.URL}/api/auth/login/get-user`, {
//           identifier: email
//         });

//         localStorage.setItem("userinfo", JSON.stringify(userResponse.data.userinfo));
//         navigate("/dashboard/properties");
//       } else {
//         setError('No account found. Please sign up first.');
//         setShowVerification(false);
//       }
//     } catch (err) {
//       console.error(err);
//       setError("Verification failed");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSocialSignIn = async (provider) => {
//         try {
//           setSocialLoading(provider);
//           setError(""); // Clear any previous errors
    
//           const response = await signUp.create({
//             strategy: `oauth_${provider}`,
//             redirectUrl: `${window.location.origin}/auth-callback`,
//             redirectUrlComplete: `${window.location.origin}/auth-callback`,
//           });
    
//           const verificationURL = response.verifications?.externalAccount?.externalVerificationRedirectURL;
    
//           if (!verificationURL) {
//             throw new Error('No verification URL received');
//           }
    
//           const authWindow = window.open(
//             verificationURL,
//             '_blank',
//             'width=600,height=700,left=300,top=100,popup=true'
//           );
    
//           if (authWindow) {
//             const checkAuth = setInterval(async () => {
//               if (authWindow.closed) {
//                 clearInterval(checkAuth);
//                 setIdentifier("");
//                 setOtpValue("");
//                 return;
//               }
    
//               try {
//                 await signUp.reload();
//                 if (signUp.verifications?.externalAccount?.status === "verified") {
//                   clearInterval(checkAuth);
//                   const emailAddress = signUp.emailAddress;
//                   if (emailAddress) {
//                     // Check if user exists in the database
//                     const verifyResponse = await axios.post(`${config.URL}/auth/login/verify-user`, {
//                       identifier: emailAddress,
//                       type: 'email'
//                     });
    
//                     if (verifyResponse.data.success) {
//                       // User exists, proceed with login
//                       const userResponse = await axios.post(`${config.URL}/auth/login/get-user`, {
//                         identifier: emailAddress
//                       });
    
//                       localStorage.setItem("userinfo", JSON.stringify(userResponse.data.userinfo));
//                       await setActive({ session: signUp.createdSessionId });
//                       authWindow.close();
//                       navigate("/dashboard/properties");
//                     } else {
//                       // User doesn't exist, show error and suggest signup
//                       setError('No account found with this Google account. Please sign up first.');
//                       authWindow.close();
//                     }
//                   }
//                 }
//               } catch (err) {
//                 console.error("Error checking auth status:", err);
//                 setError('No account found with this Google account');
//                 authWindow.close();
//               }
//             }, 1000);
//           }
//         } catch (err) {
//           console.error('Social auth error:', err);
//           setError('Authentication failed. Please try again.');
//         } finally {
//           setSocialLoading('');
//         }
//       };
    
//      if (!isLoaded) {
//     return (
//       <div className="flex items-center justify-center min-h-screen">
//         <Loader className="w-6 h-6 animate-spin" />
//       </div>
//     );
//   }

//   return (
//     <div className="flex flex-col lg:flex-row h-screen lg:h-[420px] overflow-hidden">
//       <div className="hidden lg:block w-full lg:w-1/2 h-[330px] lg:h-full relative">
//         <video
//           className="absolute inset-0 w-full h-full object-cover"
//           autoPlay
//           muted
//           loop
//           playsInline
//         >
//           <source src={video} type="video/mp4" />
//         </video>
//       </div>

//       <div className="w-full lg:w-1/2 flex items-center justify-center bg-white overflow-hidden">
//         <div className="w-full max-w-[320px] px-6 py-6 lg:py-0">
//           {!showVerification ? (
//             <>
//               <h1 className="text-[28px] font-medium text-[#44475b] text-center mb-6">
//                 Welcome to VENQ
//               </h1>

//               <button
//                 onClick={() => handleSocialSignIn("google")}
//                 disabled={loading || !!socialLoading}
//                 className="w-full flex items-center justify-center gap-3 px-5 py-2.5
//                   text-[14px] font-medium text-[#44475b] bg-white rounded-lg
//                   shadow-[0_2px_6px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_12px_rgba(0,0,0,0.12)]
//                   transition-shadow duration-300 disabled:opacity-50"
//               >
//                 {socialLoading === "google" ? (
//                   <Loader className="w-4 h-4 animate-spin" />
//                 ) : (
//                   <>
//                     <GoogleIcon className="w-5 h-5" />
//                     Continue with Google
//                   </>
//                 )}
//               </button>

//               <div className="relative my-6">
//                 <div className="absolute inset-0 flex items-center">
//                   <div className="w-full border-t border-[#e5e5e5]"></div>
//                 </div>
//                 <div className="relative flex justify-center text-sm">
//                   <span className="px-4 text-[#7c7e8c] bg-white">Or</span>
//                 </div>
//               </div>

//               {error && (
//                 <div className="mb-4 p-2.5 text-sm text-red-600 bg-red-50 rounded-lg border border-red-100">
//                   {error}
//                 </div>
//               )}

//               <div className="space-y-5">
//                 <form onSubmit={handleContinue}>
//                   <div className="space-y-4">
//                     <div className="relative">
//                       <input
//                         type="email"
//                         className="w-full pb-2 text-[14px] text-[#44475b] bg-transparent 
//                           border-b-2 border-[#00D09C] focus:outline-none focus:border-[#00D09C]
//                           placeholder:text-[#7c7e8c]"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         placeholder="Your Email Address"
//                         required
//                       />
//                     </div>

//                     <button
//                       type="submit"
//                       className="w-full py-2.5 text-[14px] font-medium text-white 
//                         bg-[#00D09C] hover:bg-[#00b386] rounded-lg transition-colors 
//                         disabled:opacity-50"
//                       disabled={loading}
//                     >
//                       {loading ? (
//                         <Loader className="w-4 h-4 animate-spin mx-auto" />
//                       ) : (
//                         "Continue"
//                       )}
//                     </button>
//                   </div>
//                 </form>

//                 <div className="text-center text-[13px] text-[#7c7e8c]">
//                   New to VENQ?{" "}
//                   <Link
//                     to="/"
//                     className="text-[#00D09C] hover:text-[#00b386] font-medium"
//                   >
//                     Sign up now
//                   </Link>
//                 </div>
//               </div>
//             </>
//           ) : (
//             <div className="animate-fadeIn">
//               <div className="flex items-center mb-6">
//                 <button
//                   onClick={() => {
//                     setShowVerification(false);
//                     setOtpValue("");
//                     setError("");
//                   }}
//                   className="p-2 hover:bg-gray-100 rounded-full transition-colors"
//                 >
//                   <ArrowLeft className="w-5 h-5 text-[#44475b]" />
//                 </button>
//                 <h2 className="text-2xl font-semibold text-[#44475b] ml-2">
//                   Enter OTP
//                 </h2>
//               </div>

//               {error && (
//                 <div className="mb-4 p-2.5 text-sm text-red-600 bg-red-50 rounded-lg border border-red-100">
//                   {error}
//                 </div>
//               )}

//               <div className="space-y-6">
//                 <div className="space-y-1">
//                   <label className="text-sm font-medium text-[#00D09C]">
//                     Your Email Address
//                   </label>
//                   <div className="border-b border-gray-200 pb-2">
//                     <span className="text-[#44475b]">{email}</span>
//                   </div>
//                 </div>

//                 <div className="space-y-2">
//                   <OTPInputLogin
//                     value={otpValue}
//                     onChange={setOtpValue}
//                     loading={loading}
//                   />
//                   <p className="text-xs text-gray-500">
//                     Enter the 6 digit OTP sent to your email
//                   </p>
//                 </div>

//                 <button
//                   onClick={handleSubmit}
//                   disabled={otpValue.length !== 6 || loading}
//                   className="w-full py-3 text-sm font-medium text-white 
//                     bg-[#00D09C] hover:bg-[#00b386] rounded-lg transition-colors 
//                     disabled:opacity-50 disabled:cursor-not-allowed"
//                 >
//                   {loading ? (
//                     <Loader className="w-4 h-4 animate-spin mx-auto" />
//                   ) : (
//                     "Submit"
//                   )}
//                 </button>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSignIn, useSignUp, useClerk } from "@clerk/clerk-react";
import { Loader, X, ArrowLeft } from 'lucide-react';
import GoogleIcon from '@mui/icons-material/Google';
import OTPInputLogin from "./OTPInputLogin";
import video from './VENQ_signup.mp4';
import axios from 'axios';
import config from "../../config";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useMediaQuery } from "@mui/material";

const Login = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { signIn, isLoaded, setActive } = useSignIn();
  
  const [identifier, setIdentifier] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState("");
  const [error, setError] = useState("");
 const [msg91RequestId, setMsg91RequestId] = useState("");
  const handleContinue = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);

      const isEmail = identifier.includes('@');
      const formattedPhone = !isEmail && !identifier.startsWith("+91") 
        ? `+91${identifier}` 
        : identifier;

      if (isEmail) {
        console.log(config.URL);
        // Use backend endpoint for email OTP
        await axios.post(`${config.URL}/auth/sendOTPEmailId`, { email: identifier });
      } else {
        // Use msg91 API for phone OTP
        const response = await axios.post(`${config.URL}/api/auth/send-otp`, {
          phone: formattedPhone
        });

        if (response.data.success) {
          setMsg91RequestId(response.data.data.request_id);
        } else {
          throw new Error(response.data.error || 'Failed to send OTP');
        }
      }

      setShowVerification(true);
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || err.message || "Failed to send verification code");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (otpValue.length !== 6) return;

    try {
      setLoading(true);
      setError("");

      const isEmail = identifier.includes('@');
      const formattedPhone = !isEmail && !identifier.startsWith("+91") 
        ? `+91${identifier}` 
        : identifier;
      let result;

      if (isEmail) {
        // Use backend endpoint for email OTP verification
        result = await axios.post(`${config.URL}/auth/verifyOTPEmail`, { 
          email: identifier, 
          otp: otpValue 
        });
      } else {
        // Use msg91 API for phone OTP verification
        result = await axios.post(`${config.URL}/api/auth/verify-otp`, {
          phone: formattedPhone,
          otp: otpValue
        });
      }

      if ((isEmail && result.data.success) || (!isEmail && result.data.success)) {
        // Check if user exists in the database
        const verifyResponse = await axios.post(`${config.URL}/api/auth/login/verify-user`, {
          identifier: isEmail ? identifier : formattedPhone,
          type: isEmail ? 'email' : 'phone'
        });

        if (verifyResponse.data.success) {
          // User exists, proceed with login
          const userResponse = await axios.post(`${config.URL}/api/auth/login/get-user`, {
            identifier: isEmail ? identifier : formattedPhone
          });

          localStorage.setItem("userinfo", JSON.stringify(userResponse.data.userinfo));
          navigate("/dashboard/properties");
        } else {
          // User doesn't exist, show error and suggest signup
          setError('No account found. Please sign up first.');
          setShowVerification(false);
        }
      } else {
        throw new Error('Invalid OTP');
      }
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || err.message || "Verification failed");
    } finally {
      setLoading(false);
    }
  };
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Fetch user info directly using the access token
        const profileResponse = await axios.get(
          'https://www.googleapis.com/oauth2/v2/userinfo',
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
  
        const userEmail = profileResponse.data.email;
        // console.log('User Email:', userEmail);
        // console.log('User Info:', profileResponse.data);
        if (userEmail) {
          // Check if user exists in the database
          const verifyResponse = await axios.post(`${config.URL}/auth/login/verify-user`, {
            identifier: userEmail,
            type: 'email'
          });

          if (verifyResponse.data.success) {
            // User exists, proceed with login
            const userResponse = await axios.post(`${config.URL}/auth/login/get-user`, {
              identifier: userEmail
            });

            localStorage.setItem("userinfo", JSON.stringify(userResponse.data.userinfo));
            await setActive({ session: signIn.createdSessionId });
            navigate("/dashboard/properties");
          } else {
            // User doesn't exist, show error and suggest signup
            setError('No account found with this Google account. Please sign up first.');
          }
        }
      } catch (error) {
        console.error('Error fetching Google user info:', error);
      }
    },
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
  });
  
  const handleSocialSignIn = async (provider) => {
    try {
      setSocialLoading(provider);
      setError(""); // Clear any previous errors
      let response;
      <GoogleLogin onSuccess={credentialResponse  => {
        response = credentialResponse;
      }} onError={() => {
        console.log('Login Failed');
      }}/>
      console.log(response);
      const verificationURL = response.verifications?.externalAccount?.externalVerificationRedirectURL;

      if (!verificationURL) {
        throw new Error('No verification URL received');
      }

      const authWindow = window.open(
        verificationURL,
        '_blank',
        'width=600,height=700,left=300,top=100,popup=true'
      );

      if (authWindow) {
        const checkAuth = setInterval(async () => {
          if (authWindow.closed) {
            clearInterval(checkAuth);
            setIdentifier("");
            setOtpValue("");
            return;
          }

          try {
            // await signUp.reload();
            console.log("Sign up:", signIn.emailAddress);
            if (signIn.verifications?.externalAccount?.status === "verified") {
              clearInterval(checkAuth);
              const emailAddress = signIn.emailAddress;
              if (emailAddress) {
                // Check if user exists in the database
                const verifyResponse = await axios.post(`${config.URL}/auth/login/verify-user`, {
                  identifier: emailAddress,
                  type: 'email'
                });

                if (verifyResponse.data.success) {
                  // User exists, proceed with login
                  const userResponse = await axios.post(`${config.URL}/auth/login/get-user`, {
                    identifier: emailAddress
                  });

                  localStorage.setItem("userinfo", JSON.stringify(userResponse.data.userinfo));
                  await setActive({ session: signIn.createdSessionId });
                  authWindow.close();
                  navigate("/dashboard/properties");
                } else {
                  // User doesn't exist, show error and suggest signup
                  setError('No account found with this Google account. Please sign up first.');
                  authWindow.close();
                }
              }
            }
          } catch (err) {
            console.error("Error checking auth status:", err);
            setError('No account found with this Google account');
            authWindow.close();
          }
        }, 1000);
      }
    } catch (err) {
      console.error('Social auth error:', err);
      setError('Authentication failed. Please try again.');
    } finally {
      setSocialLoading('');
    }
  };

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="w-6 h-6 animate-spin" />
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row lg:h-[420px] overflow-hidden">
      {!isSmallScreen && (<div className="hidden lg:block w-full lg:w-1/2 h-[330px] lg:h-full relative">
        <video
          className="absolute inset-0 w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>)}

      <div className="w-full lg:w-1/2 flex items-center justify-center bg-white overflow-hidden">
        <div className="w-full max-w-[320px] px-6 lg:px-2 py-12 lg:py-0">
          {!showVerification ? (
            // Initial Login Form
            <>
              <h1 className="lg:text-[28px] text-[23px] font-medium text-[#44475b] text-center mb-6">
                Welcome back to VENQ
              </h1>

              <button
                onClick={login}
                disabled={loading || !!socialLoading}
                className="w-full flex items-center justify-center gap-3 px-5 py-2.5
                  text-[14px] font-medium text-[#44475b] bg-white rounded-lg
                  shadow-[0_2px_6px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_12px_rgba(0,0,0,0.12)]
                  transition-shadow duration-300 disabled:opacity-50"
              >
                {socialLoading === "google" ? (
                  <Loader className="w-4 h-4 animate-spin" />
                ) : (
                  <>
                    <GoogleIcon className="w-5 h-5" />
                    Continue with Google
                  </>
                )}
              </button>

              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-[#e5e5e5]"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-4 text-[#7c7e8c] bg-white">Or</span>
                </div>
              </div>

              {error && (
                <div className="mb-4 p-2.5 text-sm text-red-600 bg-red-50 rounded-lg border border-red-100">
                  {error}
                </div>
              )}

               <div className="space-y-5">
                <form onSubmit={handleContinue}>
                  <div className="space-y-4">
                    <div className="relative">
                      <input
                        type="text"
                        className="w-full pb-2 text-[14px] text-[#44475b] bg-transparent 
                          border-b-2 border-[#00D09C] focus:outline-none focus:border-[#00D09C]
                          placeholder:text-[#7c7e8c]"
                        value={identifier}
                        onChange={(e) => setIdentifier(e.target.value)}
                        placeholder="Your Email Address or Phone Number"
                        required
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-full py-2.5 text-[14px] font-medium text-white 
                        bg-[#00b386] hover:bg-[#00b386eb] rounded-lg transition-colors 
                        disabled:opacity-50"
                      disabled={loading}
                    >
                      {loading ? (
                        <Loader className="w-4 h-4 animate-spin mx-auto" />
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </div>
                </form>

                <div className="text-center text-[13px] text-[#7c7e8c]">
                  New to VENQ?{" "}
                  <Link
                    to="/"
                    className="text-[#00b386] hover:text-[#00b386eb] font-medium"
                  >
                    Sign up now
                  </Link>
                </div>
              </div>
            </>
          ) : (
            // OTP Verification Form
            <div className="animate-fadeIn">
              <div className="flex items-center mb-6">
                <button
                  onClick={() => {
                    setShowVerification(false);
                    setOtpValue("");
                    setError("");
                  }}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                >
                  <ArrowLeft className="w-5 h-5 text-[#44475b]" />
                </button>
                <h2 className="text-2xl font-semibold text-[#44475b] ml-2">
                  Enter OTP
                </h2>
              </div>

              {error && (
                <div className="mb-4 p-2.5 text-sm text-red-600 bg-red-50 rounded-lg border border-red-100">
                  {error}
                </div>
              )}

              <div className="space-y-6">
                <div className="space-y-1">
                  <label className="text-sm font-medium text-[#00D09C]">
                    {identifier.includes('@') ? 'Your Email Address' : 'Your Phone Number'}
                  </label>
                  <div className="border-b border-gray-200 pb-2">
                    <span className="text-[#44475b]">{identifier}</span>
                  </div>
                </div>

                <div className="space-y-2">
                  <OTPInputLogin
                    value={otpValue}
                    onChange={setOtpValue}
                    loading={loading}
                  />
                  <p className="text-xs text-gray-500">
                    Enter the 6 digit OTP sent to {identifier.includes('@') ? 'your email' : 'your phone'}
                  </p>
                </div>

                <button
                  onClick={handleSubmit}
                  disabled={otpValue.length !== 6 || loading}
                  className="w-full py-3 text-sm font-medium text-white 
                    bg-[#00D09C] hover:bg-[#00b386] rounded-lg transition-colors 
                    disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <Loader className="w-4 h-4 animate-spin mx-auto" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;

