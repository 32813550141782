import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader } from 'lucide-react';
import axios from 'axios';
import config from "../../config";
import video from "../OtplessLogin/VENQ_signup.mp4";

const Step2Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, phone } = location.state || {};

  const [formData, setFormData] = useState({
    name: '',
    annualIncome: '',
    investmentGoal: '',
    preferredInvestmentAmount: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!formData.name || !formData.annualIncome || !formData.investmentGoal || !formData.preferredInvestmentAmount) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${config.URL}/api/auth/signup/step2`, {
        ...formData,
        email,
        phone
      });

      if (response.data.success) {
        navigate('/signup/step3', {
          state: {
            ...location.state,
            ...formData
          }
        });
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.response?.data?.error || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen lg:h-[420px]">
      <div className="w-full lg:w-1/2 h-[330px] lg:h-full relative rounded-t-lg lg:rounded-t-none lg:rounded-l-lg overflow-hidden">
        <video
          className="absolute inset-0 w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>

      <div className="w-full lg:w-1/2 flex items-center justify-center bg-white rounded-b-lg lg:rounded-b-none lg:rounded-r-lg relative py-6 overflow-y-auto lg:overflow-y-visible">
        <div className="w-full max-w-[268px] space-y-4 mx-auto my-8">
          <div className="space-y-1.5">
            <h1 className="text-lg font-semibold tracking-tight">Complete Your Profile</h1>
            <p className="text-[11px] text-gray-600">
              Tell us more about yourself
            </p>
          </div>

          {error && (
            <div className="p-2 text-[11px] bg-red-50 border border-red-200 text-red-600 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="relative">
              <label className="absolute -top-2 left-2 px-1 bg-white text-[10px] text-gray-500">
                Name
              </label>
              <input
                type="text"
                name="name"
                className="w-full px-3 py-2 text-[11px] bg-gray-50 text-gray-900 rounded-lg 
                  border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-500/20 
                  focus:border-gray-900 transition-all duration-300"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="space-y-2">
              <label className="text-[10px] text-gray-500">
                Annual Income
              </label>
              <div className="grid grid-cols-2 gap-2">
                {['Below ₹5L', '₹5L-₹10L', '₹10L-₹20L', 'Above ₹20L'].map((option) => (
                  <label
                    key={option}
                    className="flex items-center space-x-2 p-2 border rounded-lg cursor-pointer 
                      hover:bg-gray-50 transition-colors"
                  >
                    <input
                      type="radio"
                      name="annualIncome"
                      value={option}
                      checked={formData.annualIncome === option}
                      onChange={handleInputChange}
                      className="text-gray-900 focus:ring-gray-500"
                    />
                    <span className="text-[11px] text-gray-900">{option}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-[10px] text-gray-500">
                Investment Goal
              </label>
              <div className="grid grid-cols-1 gap-2">
                {['Short-term returns', 'Long-term appreciation', 'Passive income'].map((option) => (
                  <label
                    key={option}
                    className="flex items-center space-x-2 p-2 border rounded-lg cursor-pointer 
                      hover:bg-gray-50 transition-colors"
                  >
                    <input
                      type="radio"
                      name="investmentGoal"
                      value={option}
                      checked={formData.investmentGoal === option}
                      onChange={handleInputChange}
                      className="text-gray-900 focus:ring-gray-500"
                    />
                    <span className="text-[11px] text-gray-900">{option}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-[10px] text-gray-500">
                Preferred Investment Amount
              </label>
              <div className="grid grid-cols-2 gap-2">
                {[
                  '₹5,000 - ₹25,000',
                  '₹25,000 - ₹50,000',
                  '₹50,000 - ₹1,00,000',
                  '₹1,00,000 - ₹5,00,000',
                  '₹5,00,000 - ₹10,00,000',
                  '₹10,00,000+'
                ].map((option) => (
                  <label
                    key={option}
                    className="flex items-center space-x-2 p-2 border rounded-lg cursor-pointer 
                      hover:bg-gray-50 transition-colors"
                  >
                    <input
                      type="radio"
                      name="preferredInvestmentAmount"
                      value={option}
                      checked={formData.preferredInvestmentAmount === option}
                      onChange={handleInputChange}
                      className="text-gray-900 focus:ring-gray-500"
                    />
                    <span className="text-[11px] text-gray-900">{option}</span>
                  </label>
                ))}
              </div>
            </div>

            <button
              type="submit"
              className="w-full py-2 text-[11px] font-medium text-white bg-gray-900 
                hover:bg-gray-800 rounded-lg transition-colors disabled:opacity-50 
                disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <Loader className="w-3 h-3 animate-spin mx-auto" />
              ) : (
                "Continue"
              )}
            </button>
          </form>
        </div>

        <div className="absolute bottom-6 left-1/2 -translate-x-1/2 flex gap-2">
          {[0, 1, 2].map((step) => (
            <div
              key={step}
              className={`w-8 h-0.5 ${
                step <= 1 ? 'bg-gray-900' : 'bg-gray-300'
              } rounded-full`}
              aria-hidden="true"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step2Form;
