import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Spinner, Alert, Container } from 'react-bootstrap';
import config from "../config";
import './ContactSubmissionDetails.css';

const URL = config.URL;

function ContactSubmissionDetails() {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const formatDate = (dateString) => {
    if (!dateString) return 'No date';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Invalid date';
      
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid date';
    }
  };
  useEffect(() => {
    fetchSubmissions();
  }, []);
  const fetchSubmissions = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${URL}/api/auth/contact-submissions`);
      if (!response.ok) {
        throw new Error('Failed to fetch submissions');
      }
      const data = await response.json();
      setSubmissions(data.data);
    } catch (err) {
      setError('An error occurred while fetching submissions');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (id, newStatus) => {
    try {
      const response = await fetch(`${URL}/api/auth/contact-submissions/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      setSubmissions(submissions.map(sub => 
        sub._id === id ? { ...sub, status: newStatus } : sub
      ));
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status');
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  }

  return (
    <div className="submissions-wrapper">
      <div className="header-container">
        <h1>Contact Submissions</h1>
        <Button 
          variant="success" 
          onClick={fetchSubmissions}
          className="refresh-button"
        >
          Refresh
        </Button>
      </div>
      <div className="table-container">
        <Table hover className="submissions-table">
          <thead>
            <tr>
              <th className="col-name">Name</th>
              <th className="col-email">Email</th>
              <th className="col-mobile">Mobile</th>
              <th className="col-message">Message</th>
              <th className="col-status">Status</th>
              <th className="col-date">Date</th>
              <th className="col-action">Action</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => (
              <tr key={submission._id}>
                <td className="col-name">{submission.firstName} {submission.lastName}</td>
                <td className="col-email">{submission.email}</td>
                <td className="col-mobile">{submission.mobile}</td>
                <td className="col-message">{submission.message}</td>
                <td className="col-status">{submission.status}</td>
                <td>{formatDate(submission.submittedAt)}</td>
                <td className="col-action">
                  <Form.Select
                    value={submission.status}
                    onChange={(e) => updateStatus(submission._id, e.target.value)}
                    className="status-select"
                  >
                    <option value="new">New</option>
                    <option value="in-progress">In Progress</option>
                    <option value="resolved">Resolved</option>
                  </Form.Select>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ContactSubmissionDetails;

