// Regulations.jsx
import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  InputBase,
  IconButton,
  Container,
  Box,
  styled,
  Divider,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { Facebook, Twitter, LinkedIn } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { regulationsquestions } from "./questions";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    padding: "15px 45px 15px 45px",
    backgroundColor: "white",
    border: "1px solid rgba(20, 33, 43, 0.2)",
    borderRadius: "8px",
    color: "rgb(20, 33, 43)",
    fontSize: "1rem",
    transition: "all 0.3s ease",
    "&::placeholder": {
      color: "rgba(20, 33, 43, 0.6)",
    },
    "&:focus": {
      borderColor: "rgb(20, 33, 43)",
      boxShadow: "0 0 0 1px rgba(20, 33, 43, 0.1)",
    },
  },
}));

const UpperPart = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "250px",
  backgroundColor: "rgba(20, 33, 43)",
  color: "rgb(20, 33, 43)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "2rem 1rem",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    minHeight: "200px",
    padding: "1.5rem 1rem",
  },
}));

const QuestionContainer = styled(Box)({
  marginBottom: "12px",
  borderRadius: "10px",
  overflow: "hidden",
  backgroundColor: "white",
  transition: "all 0.3s ease",
  border: "1px solid rgba(20, 33, 43, 0.15)",
  "&:hover": {
    boxShadow: "0 4px 20px rgba(20, 33, 43, 0.1)",
    transform: "translateY(-2px)",
  },
});

const QuestionHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 24px",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(20, 33, 43, 0.02)",
  },
});

const AnswerContainer = styled(Box)({
  padding: "20px 24px",
  backgroundColor: "rgba(20, 33, 43, 0.02)",
  borderTop: "1px solid rgba(20, 33, 43, 0.15)",
});

const BackButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  color: 'rgb(20, 33, 43)',
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 0.7
  }
});

const Regulations = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [questionsToShow, setQuestionsToShow] = useState(regulationsquestions);
  const [openDropdown, setOpenDropdown] = useState(null);

  // Handle incoming state and show selected question
  useEffect(() => {
    if (location.state?.selectedQuestion) {
      const selectedQ = regulationsquestions.find(
        q => q.question.toLowerCase() === location.state.selectedQuestion.toLowerCase()
      );
      
      if (selectedQ) {
        if (location.state.showOnlySelected) {
          setQuestionsToShow([selectedQ]);
          setOpenDropdown(0);
        } else {
          const index = regulationsquestions.findIndex(
            q => q.question.toLowerCase() === location.state.selectedQuestion.toLowerCase()
          );
          setQuestionsToShow(regulationsquestions);
          setTimeout(() => {
            const element = document.getElementById(`question-${index}`);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }, 100);
        }
      }
    } else {
      setQuestionsToShow(regulationsquestions);
    }
  }, [location.state]);

  // Handle search functionality
  useEffect(() => {
    if (location.state?.showOnlySelected) return;
    
    if (searchTerm) {
      const filtered = regulationsquestions.filter(q => 
        q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.answer.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setQuestionsToShow(filtered);
    } else {
      setQuestionsToShow(regulationsquestions);
    }
  }, [searchTerm, location.state?.showOnlySelected]);

  const handleBackToAll = useCallback(() => {
    setQuestionsToShow(regulationsquestions);
    setSearchTerm("");
    setOpenDropdown(null);
    navigate(location.pathname, { 
      replace: true,
      state: null 
    });
  }, [navigate, location.pathname]);

  return (
    <Box sx={{ bgcolor: "white", minHeight: "100vh", color: "rgb(20, 33, 43)" }}>
      {/* Header Section */}
      <UpperPart>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "610px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {/* Header content */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <img
                          src="images/VENQ_BOLD_small1.png"
                          alt="VENQ Logo"
                          style={{
                            height: isSmallScreen ? "24px" : "30px",
                            objectFit: "contain",
                          }}
                        />
                        <Typography
                          onClick={() => navigate("/")}
                          sx={{
                            color: "rgb(20, 33, 43)",
                            backgroundColor: "white",
                            cursor: "pointer",
                            fontWeight: 500,
                            fontSize: "0.95rem",
                            padding: "8px 20px",
                            borderRadius: "6px",
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                            transition: "all 0.3s ease",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                              transform: "translateY(-1px)",
                              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                            },
                          }}
                        >
                          Go to VENQ
                        </Typography>
                      </Box>
            
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: isSmallScreen ? "1.75rem" : "2.5rem",
                          fontWeight: 600,
                          mt: 4,
                          mb: 3,
                          color: "white",
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        Advice and Answers from the VENQ Team
                      </Typography>
                {/* Search Bar */}
                <Box sx={{ width: "100%", maxWidth: "600px", position: "relative" }}>
                  <SearchIcon
                    sx={{
                      position: "absolute",
                      left: "16px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "rgb(20, 33, 43, 0.6)",
                      zIndex: 1,
                    }}
                  />
                  <StyledInputBase 
                    placeholder="Search..." 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Box>
                </Box>
            </UpperPart>

      {/* Questions Section */}
      <Container maxWidth="lg" sx={{ mt: 8, mb: 4, px: { xs: 2, sm: 3, md: 4 } }}>
        {location.state?.showOnlySelected && (
          <Box sx={{ maxWidth: "800px", mx: "auto", mb: 4 }}>
            <BackButton onClick={handleBackToAll}>
              <ChevronRightOutlinedIcon sx={{ transform: 'rotate(180deg)' }} />
              <Typography>Back to all questions</Typography>
            </BackButton>
          </Box>
        )}

        <Box sx={{ maxWidth: "800px", mx: "auto" }}>
          {questionsToShow.map((item, index) => (
            <QuestionContainer key={`${item.question}-${index}`} id={`question-${index}`}>
              <QuestionHeader onClick={() => setOpenDropdown(openDropdown === index ? null : index)}>
                <Typography sx={{
                  fontSize: { xs: "1rem", sm: "1.1rem" },
                  fontWeight: 500,
                  color: "rgb(20, 33, 43)",
                  flex: 1,
                  pr: 2,
                }}>
                  {item.question}
                </Typography>
                {openDropdown === index ? (
                  <KeyboardArrowDownOutlinedIcon sx={{ color: "rgb(20, 33, 43)", fontSize: 24 }} />
                ) : (
                  <ChevronRightOutlinedIcon sx={{ color: "rgb(20, 33, 43)", fontSize: 24 }} />
                )}
              </QuestionHeader>
              {openDropdown === index && (
                <AnswerContainer>
                  <Typography sx={{
                    color: "rgb(20, 33, 43, 0.8)",
                    lineHeight: 1.7,
                    fontSize: { xs: "0.9rem", sm: "0.95rem" },
                    letterSpacing: "0.2px",
                  }}>
                    {item.answer}
                  </Typography>
                </AnswerContainer>
              )}
            </QuestionContainer>
          ))}
        </Box>
      </Container>

      {/* Note Section */}
      <Container maxWidth="lg" sx={{ mb: 8, px: { xs: 2, sm: 3, md: 4 } }}>
        <Box sx={{
          maxWidth: "800px",
          mx: "auto",
          py: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}>
          <Divider sx={{ width: "40%", borderColor: "rgba(20, 33, 43, 0.4)" }} />
          <Typography sx={{
            fontSize: "0.95rem",
            lineHeight: 1.7,
            textAlign: "center",
            maxWidth: "600px",
            color: "rgb(20, 33, 43, 0.8)",
            "& a": {
              color: "rgb(20, 33, 43)",
              fontWeight: 500,
              transition: "all 0.2s ease",
              textDecoration: "none",
              "&:hover": {
                color: "rgb(20, 33, 43, 0.7)",
              }
            }
          }}>
            Please note that these FAQs provide general information and do not constitute legal advice.
            For specific legal queries, it is advisable to consult with our team at{" "}
            <a href="mailto:team@venq.in">team@venq.in</a>.
          </Typography>
          <Divider sx={{ width: "40%", borderColor: "rgba(20, 33, 43, 0.4)" }} />
        </Box>
      </Container>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          py: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderTop: "1px solid rgba(20, 33, 43, 0.15)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mb: 3 }}>
          <img
            src="images/VENQ_BOLD_Big.png"
            alt="VENQ Logo"
            style={{ width: "150px", height: "auto", margin: "0 auto" }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          <IconButton sx={{
            color: "rgb(20, 33, 43)",
            transition: "all 0.3s ease",
            "&:hover": {
              color: "rgba(20, 33, 43, 0.7)",
              transform: "translateY(-2px)",
            },
            mx: 1,
          }}>
            <Facebook />
          </IconButton>
          <IconButton sx={{
            color: "rgb(20, 33, 43)",
            transition: "all 0.3s ease",
            "&:hover": {
              color: "rgba(20, 33, 43, 0.7)",
              transform: "translateY(-2px)",
            },
            mx: 1,
          }}>
            <Twitter />
          </IconButton>
          <IconButton sx={{
            color: "rgb(20, 33, 43)",
            transition: "all 0.3s ease",
            "&:hover": {
              color: "rgba(20, 33, 43, 0.7)",
              transform: "translateY(-2px)",
            },
            mx: 1,
          }}>
            <LinkedIn />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Regulations;