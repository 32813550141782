import React from "react";
import { X } from "lucide-react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <>
      {" "}
      <div className="inset-0 z-[100] overflow-y-auto">
        {" "}
        <div className="flex min-h-screen justify-center p-4 ">
          {" "}
          <div
            className="fixed inset-0 bg-black/50 backdrop-blur-md"
            onClick={onClose}
            aria-hidden="true"
          />{" "}
          <div className="relative w-[90%] max-w-4xl h-[420px] bg-white rounded-xl overflow-auto transform transition-all">
            {" "}
            <button
              onClick={onClose}
              className="absolute top-2 right-2 p-1.5 text-black bg-white hover:bg-black/10 rounded-full z-[101]"
            >
              {" "}
              <X className="w-5 h-5 " />{" "}
            </button>{" "}
            {React.isValidElement(children)
            ? React.cloneElement(children, { onClose })
            : children}{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </>
  );
};

export default Modal;
