// import React, { useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   InputBase,
//   IconButton,
//   Grid,
//   AvatarGroup,
//   Container,
//   Menu,
//   MenuItem,
// } from "@mui/material";
// import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
// import { Search as SearchIcon, Menu as MenuIcon } from "@mui/icons-material";
// import { Facebook, Twitter, LinkedIn } from "@mui/icons-material";
// import { Avatar } from "@mui/material";
// import CardContent from "@mui/material/CardContent";
// import Card from "@mui/material/Card";
// import { Link, useNavigate } from "react-router-dom";
// import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined"; // Add this import
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

// const typoStyle = {
//   color: "#fff",
//   marginTop: "5%",
//   fontFamily: "Bebas Neue,sans-serif",
//   fontSize: "3rem",
// };

// const questions = [
//   {
//     question: "What are CCDs (Convertible Debentures)?",
//     answer:
//       "Convertible Debentures (CCDs) are financial instruments that can be converted into equity shares at a later date. VENQ issues CCDs as a way for investors to participate in the potential returns of a real estate investment.",
//   },
//   {
//     question: "How does the issuance of CCDs work on VENQ?",
//     answer:
//       "Upon a successful fundraise, VENQ initiates a private placement offer for CCDs to those who invested. The issuance follows the rules and regulations outlined in the Companies Act 2013.",
//   },
//   {
//     question: "What legal protections do CCDs provide to investors?",
//     answer:
//       "CCD holders have the right to convert their debentures into equity shares, thereby becoming shareholders in the underlying real estate asset. Legal provisions within the issuance agreement protect the rights of CCD holders.",
//   },
//   {
//     question:
//       "Is VENQ regulated by SEBI (Securities and Exchange Board of India)?",
//     answer:
//       "VENQ issues CCDs through private placement, targeting a select group of investors. As this falls outside the public offering domain, SEBI regulations related to public issues do not apply directly. However, we operate within the broader framework of financial regulations.",
//   },
//   {
//     question:
//       "How are CCDs different from traditional real estate investments?",
//     answer:
//       "CCDs offer a unique investment avenue by providing a stake in a real estate asset without the need for full ownership. Investors benefit from potential appreciation while having the flexibility to convert their debentures into equity shares at the date of sale.",
//   },
//   {
//     question:
//       "What happens if an investor wants to exit their investment before conversion?",
//     answer:
//       "Investors looking to exit their investment before the conversion period ends may explore secondary market options if available. VENQ is actively working on introducing a secondary market to facilitate such transactions.",
//   },
//   {
//     question: "What legal documentation is involved in CCD issuance?",
//     answer:
//       "CCD issuance is governed by legal agreements, including the agreement to sell, private placement offer, and other relevant documents. These agreements outline the terms, rights, and obligations of the parties involved.",
//   },
//   {
//     question:
//       "How does VENQ ensure compliance with company laws during CCD issuance?",
//     answer:
//       "VENQ adheres to the provisions of the Companies Act 2013 during the issuance of CCDs, ensuring legal compliance and transparency in the process. Our legal team oversees the entire process to safeguard the interests of both VENQ and investors.",
//   },
// ];

// const CCD = () => {
//   const navigate = useNavigate(); // Initialize useNavigate
//   const [openDropdown, setOpenDropdown] = useState(null);

//   const handleGoToVenQ = () => {
//     // Redirect to the home page
//     navigate("/");
//   };

//   const toggleDropdown = (index) => {
//     setOpenDropdown(openDropdown === index ? null : index);
//   };
//   return (
//     <>
//       <div style={{ backgroundColor: "#1b527b", padding: "40px 0px 20px" }}>
//         <Container maxWidth="lg">
//           <AppBar position="static" color="transparent">
//             <Toolbar>
//               {/* Logo on the left */}
//               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//                 <img
//                   src="images/VENQ_BOLD_small1.png"
//                   alt="Logo"
//                   style={{ maxWidth: "100%" }}
//                 />
//               </Typography>

//               {/* Tool icons on the right */}
//               <IconButton
//                 color="inherit"
//                 onClick={handleGoToVenQ}
//                 sx={{ color: "#fff", fontSize: "20px" }}
//               >
//                 Go to venQ
//               </IconButton>
//             </Toolbar>
//           </AppBar>
//         </Container>

//         {/* Text input box */}
//         <Container maxWidth="lg" sx={{ marginTop: "5%" }}>
//           <InputBase
//             placeholder="Search..."
//             inputProps={{ "aria-label": "search" }}
//             fullWidth
//             startAdornment={<SearchIcon style={{ color: "#888888" }} />}
//             sx={{
//               backgroundColor: "rgba(255, 255, 255, 0.8)",
//               borderRadius: "10px",
//               padding: "15px",
//               marginTop: "10px",
//             }}
//           />
//         </Container>
//       </div>

//       <Container
//         style={{
//           height: "auto",
//           padding: "20px 0",
//           marginTop: "100px",
//           border: "1px solid #ddd",
//           borderRadius: "10px",
//         }}
//       >
//         {/* Questions Container */}
//         <Container maxWidth="md" sx={{ marginTop: "40px" }}>
//           {questions.map((item, index) => (
//             <div
//               key={index}
//               onClick={() => toggleDropdown(index)}
//               style={{
//                 position: "relative",
//                 marginBottom: "10px", // Add margin-bottom to create space between questions
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                   padding: "15px",
//                   backgroundColor: "rgba(255, 255, 255, 0.8)",
//                   borderRadius: "10px",
//                   cursor: "pointer",
//                   "&:hover": {
//                     backgroundColor: "black",
//                   },
//                 }}
//               >
//                 <Typography
//                   style={{ fontSize: "1.25rem", marginRight: "10px" }}
//                 >
//                   {item.question}
//                 </Typography>
//                 {openDropdown === index ? (
//                   <KeyboardArrowDownOutlinedIcon
//                     style={{ fontSize: "20px", color: "#000" }}
//                   />
//                 ) : (
//                   <ChevronRightOutlinedIcon
//                     style={{ fontSize: "20px", color: "#000" }}
//                   />
//                 )}
//               </div>
//               {openDropdown === index && (
//                 <div
//                   style={{
//                     backgroundColor: "#fff",
//                     padding: "10px",
//                     borderRadius: "5px",
//                     marginTop: "5px", // Add margin-top for space between question and dropdown
//                     top: "100%", // Position below the question
//                     left: 0,
//                     width: "100%",
//                     zIndex: 1,
//                   }}
//                 >
//                   <Typography>{item.answer}</Typography>
//                 </div>
//               )}
//             </div>
//           ))}
//         </Container>
//       </Container>

//       <Container
//         style={{
//           height: "auto",
//           padding: "20px 0",
//           marginTop: "100px",
//         }}
//       >
//         {/* Top Div with Logo */}
//         <div style={{ textAlign: "center", marginBottom: "20px" }}>
//           <img
//             src="images/VENQ_BOLD_Big.png"
//             alt="Logo"
//             style={{ width: "150px" }}
//           />
//         </div>

//         {/* Bottom Div with Social Icons */}
//         <div style={{ textAlign: "center" }}>
//           {/* Add your social icons here */}
//           <IconButton color="inherit" style={{ margin: "0 10px" }}>
//             <Facebook />
//           </IconButton>
//           <IconButton color="inherit" style={{ margin: "0 10px" }}>
//             <Twitter />
//           </IconButton>
//           <IconButton color="inherit" style={{ margin: "0 10px" }}>
//             <LinkedIn />
//           </IconButton>
//         </div>
//       </Container>
//     </>
//   );
// };

// export default CCD;


// CCD.jsx
// CCD.jsx
import React, { useState, useEffect ,useCallback} from "react";
import {
  Typography,
  InputBase,
  IconButton,
  Container,
  Box,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ccdquestions } from "./questions";
import { Search as SearchIcon } from "@mui/icons-material";
import { Facebook, Twitter, LinkedIn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useLocation } from 'react-router-dom';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    padding: "15px 45px 15px 45px",
    backgroundColor: "white",
    border: "1px solid rgba(20, 33, 43, 0.2)",
    borderRadius: "8px",
    color: "rgb(20, 33, 43)",
    fontSize: "1rem",
    transition: "all 0.3s ease",
    "&::placeholder": {
      color: "rgba(20, 33, 43, 0.6)",
    },
    "&:focus": {
      borderColor: "rgb(20, 33, 43)",
      boxShadow: "0 0 0 1px rgba(20, 33, 43, 0.1)",
    },
  },
}));

const UpperPart = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "250px",
  backgroundColor: "rgba(20, 33, 43)",
  color: "rgb(20, 33, 43)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "2rem 1rem",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    minHeight: "200px",
    padding: "1.5rem 1rem",
  },
}));

const QuestionContainer = styled(Box)({
  marginBottom: "12px",
  borderRadius: "10px",
  overflow: "hidden",
  backgroundColor: "white",
  transition: "all 0.3s ease",
  border: "1px solid rgba(20, 33, 43, 0.15)",
  "&:hover": {
    boxShadow: "0 4px 20px rgba(20, 33, 43, 0.1)",
    transform: "translateY(-2px)",
  },
});

const QuestionHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 24px",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "rgba(20, 33, 43, 0.02)",
  },
});

const AnswerContainer = styled(Box)({
  padding: "20px 24px",
  backgroundColor: "rgba(20, 33, 43, 0.02)",
  borderTop: "1px solid rgba(20, 33, 43, 0.15)",
});

const BackButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  color: 'rgb(20, 33, 43)',
  transition: 'opacity 0.3s ease',
  '&:hover': {
    opacity: 0.7
  }
});

const CCD = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [questionsToShow, setQuestionsToShow] = useState(ccdquestions);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleIncomingState = useCallback(() => {
    const state = location.state;
    if (!state) return;

    const { selectedQuestion, showOnlySelected } = state;
    if (!selectedQuestion) return;

    // Find the matching question (case-insensitive)
    const selectedQ = ccdquestions.find(
      q => q.question.toLowerCase() === selectedQuestion.toLowerCase()
    );

    if (!selectedQ) return;

    if (showOnlySelected) {
      // Show only the selected question
      setQuestionsToShow([selectedQ]);
      setOpenDropdown(0);
    } else {
      // Find index and scroll to question
      const index = ccdquestions.findIndex(
        q => q.question.toLowerCase() === selectedQuestion.toLowerCase()
      );
      
      setQuestionsToShow(ccdquestions);
      setOpenDropdown(index);

      // Scroll after a short delay to ensure rendering
      requestAnimationFrame(() => {
        const element = document.getElementById(`question-${index}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    }
  }, [location.state]);

  // Handle incoming navigation state
  useEffect(() => {
    handleIncomingState();
  }, [handleIncomingState]);

  // Handle search independently
  useEffect(() => {
    // Don't filter if we're showing a selected question
    if (location.state?.showOnlySelected) return;

    if (searchTerm) {
      const filtered = ccdquestions.filter(q => 
        q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.answer.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setQuestionsToShow(filtered);
    } else {
      setQuestionsToShow(ccdquestions);
    }
  }, [searchTerm, location.state?.showOnlySelected]);

  const toggleDropdown = useCallback((index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  }, [openDropdown]);

  const handleBackToAll = useCallback(() => {
    setQuestionsToShow(ccdquestions);
    setSearchTerm("");
    setOpenDropdown(null);
    navigate(location.pathname, { 
      replace: true,
      state: null
    });
  }, [navigate, location.pathname]);

  return (
    <Box sx={{ bgcolor: "white", minHeight: "100vh", color: "rgb(20, 33, 43)" }}>
      {/* Header Section */}
      <UpperPart>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "610px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {/* Header content */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <img
                          src="images/VENQ_BOLD_small1.png"
                          alt="VENQ Logo"
                          style={{
                            height: isSmallScreen ? "24px" : "30px",
                            objectFit: "contain",
                          }}
                        />
                        <Typography
                          onClick={() => navigate("/")}
                          sx={{
                            color: "rgb(20, 33, 43)",
                            backgroundColor: "white",
                            cursor: "pointer",
                            fontWeight: 500,
                            fontSize: "0.95rem",
                            padding: "8px 20px",
                            borderRadius: "6px",
                            border: "1px solid rgba(255, 255, 255, 0.3)",
                            transition: "all 0.3s ease",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                              transform: "translateY(-1px)",
                              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                            },
                          }}
                        >
                          Go to VENQ
                        </Typography>
                      </Box>
            
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: isSmallScreen ? "1.75rem" : "2.5rem",
                          fontWeight: 600,
                          mt: 4,
                          mb: 3,
                          color: "white",
                          fontFamily: "Inter, sans-serif",
                        }}
                      >
                        Advice and Answers from the VENQ Team
                      </Typography>
                {/* Search Bar */}
                <Box sx={{ width: "100%", maxWidth: "600px", position: "relative" }}>
                  <SearchIcon
                    sx={{
                      position: "absolute",
                      left: "16px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "rgb(20, 33, 43, 0.6)",
                      zIndex: 1,
                    }}
                  />
                  <StyledInputBase 
                    placeholder="Search..." 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Box>
                </Box>
            </UpperPart>

      {/* Questions Section */}
      <Container maxWidth="lg" sx={{ mt: 8, mb: 8, px: { xs: 2, sm: 3, md: 4 } }}>
        {location.state?.showOnlySelected && (
          <Box sx={{ maxWidth: "800px", mx: "auto", mb: 4 }}>
            <BackButton onClick={handleBackToAll}>
              <ChevronRightOutlinedIcon sx={{ transform: 'rotate(180deg)' }} />
              <Typography>Back to all questions</Typography>
            </BackButton>
          </Box>
        )}

        <Box sx={{ maxWidth: "800px", mx: "auto" }}>
          {questionsToShow.map((item, index) => (
            <QuestionContainer 
              key={`${item.question}-${index}`}
              id={`question-${index}`}
            >
              <QuestionHeader onClick={() => toggleDropdown(index)}>
                <Typography
                  sx={{
                    fontSize: { xs: "1rem", sm: "1.1rem" },
                    fontWeight: 500,
                    color: "rgb(20, 33, 43)",
                    flex: 1,
                    pr: 2,
                  }}
                >
                  {item.question}
                </Typography>
                {openDropdown === index ? (
                  <KeyboardArrowDownOutlinedIcon sx={{ color: "rgb(20, 33, 43)", fontSize: 24 }} />
                ) : (
                  <ChevronRightOutlinedIcon sx={{ color: "rgb(20, 33, 43)", fontSize: 24 }} />
                )}
              </QuestionHeader>
              {openDropdown === index && (
                <AnswerContainer>
                  <Typography
                    sx={{
                      color: "rgb(20, 33, 43, 0.8)",
                      lineHeight: 1.7,
                      fontSize: { xs: "0.9rem", sm: "0.95rem" },
                      letterSpacing: "0.2px",
                    }}
                  >
                    {item.answer}
                  </Typography>
                </AnswerContainer>
              )}
            </QuestionContainer>
          ))}
        </Box>
      </Container>

      {/* Footer Section */}
      <Box
        component="footer"
        sx={{
          py: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderTop: "1px solid rgba(20, 33, 43, 0.15)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: 3,
          }}
        >
          <img
            src="images/VENQ_BOLD_Big.png"
            alt="VENQ Logo"
            style={{
              width: "150px",
              height: "auto",
              margin: "0 auto",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <IconButton
            sx={{
              color: "rgb(20, 33, 43)",
              transition: "all 0.3s ease",
              "&:hover": {
                color: "rgba(20, 33, 43, 0.7)",
                transform: "translateY(-2px)",
              },
              mx: 1,
            }}
          >
            <Facebook />
          </IconButton>
          <IconButton
            sx={{
              color: "rgb(20, 33, 43)",
              transition: "all 0.3s ease",
              "&:hover": {
                color: "rgba(20, 33, 43, 0.7)",
                transform: "translateY(-2px)",
              },
              mx: 1,
            }}
          >
            <Twitter />
          </IconButton>
          <IconButton
            sx={{
              color: "rgb(20, 33, 43)",
              transition: "all 0.3s ease",
              "&:hover": {
                color: "rgba(20, 33, 43, 0.7)",
                transform: "translateY(-2px)",
              },
              mx: 1,
            }}
          >
            <LinkedIn />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CCD;