// import React, { useEffect } from "react";
// import {
//   loadOTPlessScriptSignUp,
//   unloadOTPlessScript,
// } from "./../../utils/otpless";
// import { useNavigate } from "react-router-dom";
// import video from "../OtplessLogin/new login video.mp4";
// import "./OtplessSignup.css";

// export default function OptlessSignup() {
//   const navigate = useNavigate();
//   useEffect(() => {
//     loadOTPlessScriptSignUp();
//     window.otpless = (otplessUser) => {
//       navigate("name", { state: otplessUser });
//     };
//     return () => {
//       unloadOTPlessScript();
//     };
//   });
//     return (
//       <div className="flex flex-col md:flex-row h-screen overflow-hidden bg-white">
//         {/* Left side - Video section */}
//         <div className="w-full md:w-3/5 h-[40vh] md:h-full flex items-center justify-center p-4">
//           <div className="rounded-xl overflow-hidden w-full h-full">
//             <video
//               className="w-full h-full object-cover"
//               autoPlay
//               muted
//               loop
//               playsInline
//             >
//               <source src={video} type="video/mp4" />
//               Your browser does not support the video tag.
//             </video>
//           </div>
//         </div>

//         {/* Right side container */}
//         <div className="w-full md:w-2/5 h-[60vh] md:h-full flex items-center justify-center">
//           <div className="w-full max-w-[280px] h-auto flex items-center justify-center relative">
//             <div id="otpless-login-page" className="relative">
//               <div className="absolute left-0 right-0 -bottom-8 text-center">
//                 <p className="text-sm md:text-base text-gray-600">
//                   Already have an account? <a href="/login" className="text-purple-600 hover:text-purple-700 font-medium">
//                     Log in here
//                   </a> to continue
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//   );
// }





import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSignUp } from "@clerk/clerk-react";
import { Loader, ChevronDown, ArrowLeft } from 'lucide-react';
import OTPInput from "./OTPInput";
import video from "../OtplessLogin/VENQ_signup.mp4";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from 'axios';
import config from "../../config";
import { useGoogleLogin } from "@react-oauth/google";
import Modal from "../../Herosection/Modal";
import OtplessLogin from "../OtplessLogin/OtplessLogin";
import { useMediaQuery } from "@mui/material";

const OptlessSignup = ({ onClose }) => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const { signUp, isLoaded, setActive } = useSignUp();
  const [msg91RequestId, setMsg91RequestId] = useState("");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    name: "",
    annualIncome: "",
    investmentGoal: "",
    preferredInvestmentAmount: "",
  });

  const [otpStates, setOtpStates] = useState({
    email: { show: false, code: "", verified: false },
    phone: { show: false, code: "", verified: false },
  });

  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState("");
  const [error, setError] = useState("");



  useEffect(() => {
    // Load phone.email script on mount
   
  }, []);

  const handleSelectChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleButtonClick = async (type) => {
    if (type === "phone") {
      if (!otpStates.phone.show) {
        await handlePhoneOTP();
      } else {
        await handlePhoneVerify();
      }
    } else if (type === "email") {
      if (!otpStates.email.show) {
        await handleEmailOTP();
      } else {
        await handleVerify("email");
      }
    }
  };


  const handlePhoneOTP = async () => {
    try {
      setLoading(true);
      setError("");
      console.log("Phone number:", formData.phone);
      const response = await axios.post(`${config.URL}/api/auth/send-otp`, {
        phone: formData.phone
      });

      if (response.data.success) {
        setOtpStates(prev => ({
          ...prev,
          phone: { ...prev.phone, show: true }
        }));
        setMsg91RequestId(response.data.data.request_id);
      } else {
        throw new Error(response.data.error || 'Failed to send OTP');
      }
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to send verification code");
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneVerify = async () => {
    try {
      setLoading(true);
      setError("");

      const response = await axios.post(`${config.URL}/api/auth/verify-otp`, {
        phone: formData.phone,
        otp: otpStates.phone.code
      });
      console.log("Phone verification response:", response.data);
      if (response.data.success) {
        setOtpStates(prev => ({
          ...prev,
          phone: {
            ...prev.phone,
            verified: true,
            show: false,
          }
        }));
      } else {
        throw new Error(response.data.error || 'Invalid OTP');
      }
    } catch (err) {
      console.error(err);
      setError(err.message || "Verification failed");
    } finally {
      setLoading(false);
    }
  };
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Fetch user info directly using the access token
        const profileResponse = await axios.get(
          'https://www.googleapis.com/oauth2/v2/userinfo',
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
  
        const userEmail = profileResponse.data.email;
        // console.log('User Email:', userEmail);
        // console.log('User Info:', profileResponse.data);
        if (userEmail) {
          // Check if user exists in the database
          try{
          const verifyResponse = await axios.post(`${config.URL}/auth/login/verify-user`, {
            identifier: userEmail,
            type: 'email'
          });
          console.log('Verify Response:', verifyResponse.data);
          if (verifyResponse.data.success){
            setError('Account found with this Google account. Please sign in.');
          }
          // else{
          //   setFormData(prev => ({ ...prev, email: userEmail }));
          //   setOtpStates(prev => ({
          //     ...prev,
          //     email: { show: false, code: '', verified: true }
          //   }));
          //   setError('');
          // }
        } catch (error) {
          console.error('Error fetching Google user info:', error);
          setFormData(prev => ({ ...prev, email: userEmail }));
            setOtpStates(prev => ({
              ...prev,
              email: { show: false, code: '', verified: true }
            }));
            setError('');
        }
        }
      } catch (error) {
        console.error('Error fetching Google user info:', error);
      }
    },
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
  });
  const handleSocialSignIn = async (provider) => {
    try {
      if (!signUp) return;
      setSocialLoading(provider);

      let oauthProvider = provider;
      if (provider === 'linkedin') {
        oauthProvider = 'linkedin_oidc';
      }

      const response = await signUp.create({
        strategy: `oauth_${oauthProvider}`,
        redirectUrl: `${window.location.origin}/auth-callback`,
        redirectUrlComplete: `${window.location.origin}/auth-callback`,
      });

      const verificationURL = response.verifications?.externalAccount?.externalVerificationRedirectURL;

      if (!verificationURL) {
        throw new Error('No verification URL received');
      }

      const authWindow = window.open(
        verificationURL,
        '_blank',
        'width=600,height=700,left=300,top=100,popup=true'
      );

      if (authWindow) {
        const checkAuth = setInterval(async () => {
          if (authWindow.closed) {
            clearInterval(checkAuth);
            setFormData({ email: "", phone: "" });
            setOtpStates({
              email: { show: false, code: "", verified: false },
              phone: { show: false, code: "", verified: false },
            });
            return;
          }

          try {
            await signUp.reload();
            if (signUp.verifications?.externalAccount?.status === "verified") {
              clearInterval(checkAuth);
              const emailAddress = signUp.emailAddress;
              if (emailAddress) {
                const verifyResponse = await axios.post(`${config.URL}/auth/login/verify-user`, {
                  identifier: emailAddress,
                  type: 'email'
                });
                if (verifyResponse.data.success){
                  setError('Account found with this Google account. Please sign in.');
                  authWindow.close();
                }
                else{
                  setFormData(prev => ({ ...prev, email: emailAddress }));
                  setOtpStates(prev => ({
                    ...prev,
                    email: { show: false, code: '', verified: true }
                  }));
                  setError('');
                  authWindow.close();
                }
              }
            }
          } catch (err) {
            console.error("Error checking auth status:", err);
          }
        }, 1000);
      }

    } catch (err) {
      console.error('Social auth error:', err);
      setError('Authentication failed. Please try again.');
    } finally {
      setSocialLoading('');
    }
  };

  const handleEmailOTP = async () => {
    try {
      setLoading(true);
      setError("");
      console.log("Email:", formData.email);
      
      const response = await axios.post(`${config.URL}/auth/sendOTPEmailId`, {
        email: formData.email
      });

      if (response.data.success) {
        setOtpStates(prev => ({
          ...prev,
          email: { ...prev.email, show: true }
        }));
      } else {
        throw new Error(response.data.error || 'Failed to send OTP');
      }
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to send verification code");
    } finally {
      setLoading(false);
    }
  };
  let globalResult;
  const handleVerify = async (type) => {
    try {
      setLoading(true);
      setError("");

      if (type === "email") {
        const response = await axios.post(`${config.URL}/auth/verifyOTPEmail`, {
          email: formData.email,
          otp: otpStates.email.code
        });

        if (response.data.success) {
          setOtpStates((prev) => ({
            ...prev,
            email: {
              ...prev.email,
              verified: true,
              show: false,
            },
          }));
        } else {
          throw new Error(response.data.error || 'Invalid OTP');
        }
      } else {
        const result = await signUp.attemptPhoneNumberVerification({
          code: otpStates.phone.code,
        });

        setOtpStates((prev) => ({
          ...prev,
          phone: {
            ...prev.phone,
            verified: true,
            show: false,
          },
        }));

        if (result?.createdSessionId) {
          try {
            await setActive({ session: result.createdSessionId });
          } catch (sessionErr) {
            console.error("Session activation error:", sessionErr);
          }
        }
      }
    } catch (err) {
      console.error(err);
      setError(err.message || "Verification failed");
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = () => {
    if (step === 1 && otpStates.email.verified && otpStates.phone.verified) {
    // if (step === 1 && otpStates.email.verified) {
      setStep(2);
    } else if (step === 2) {
      if (formData.name && formData.annualIncome && formData.investmentGoal && formData.preferredInvestmentAmount) {
        setStep(3);
      }
    } else if (step === 3) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      console.log(formData);
      const response = await axios.post(`${config.URL}/auth/signup/step2`, formData);
      if (response.data.success) {
        const userResponse = await axios.post(`${config.URL}/api/auth/login/get-user`, {
          identifier: formData.email
        });

        localStorage.setItem("userinfo", JSON.stringify(userResponse.data.userinfo));
        if(globalResult?.createdSessionId){
          try {
            await setActive({ session: globalResult.createdSessionId });
          } catch (sessionErr) {
            console.error("Session activation error:", sessionErr);
          }
        }
        setTimeout(() => {
          navigate("/dashboard/properties");
          if (onClose) onClose(); // Close the modal if it exists
        }, 1500);
      } else {
        setError(response.data.message || "Signup failed");
      }
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.error || "An error occurred during signup");
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="space-y-1.5">
              <h1 className="text-[28px] font-medium text-[#44475b] text-center">
                Welcome to VENQ
              </h1>
            </div>
            
            <div className="grid gap-2">
              <button
                onClick={login}
                disabled={loading || !!socialLoading}
                className="w-full flex items-center justify-center gap-3 px-5 py-2.5
                  text-[14px] font-medium text-[#44475b] bg-white rounded-lg
                  shadow-[0_2px_6px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_12px_rgba(0,0,0,0.12)]
                  transition-shadow duration-300 disabled:opacity-50"
              >
                {socialLoading === "google" ? (
                  <Loader className="w-4 h-4 animate-spin" />
                ) : (
                  <>
                    <GoogleIcon className="w-5 h-5" />
                    Continue with Google
                  </>
                )}
              </button>
            </div>
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-[11px]">
                <span className="px-2 text-gray-500 bg-white">Or continue with</span>
              </div>
            </div>
            <div className="space-y-4">
              <div className="space-y-1.5">
                <div className="relative">
                  <label className="absolute -top-2 left-2 px-1 bg-white text-[10px] text-gray-500">
                    Email
                  </label>
                  <input
                    type="email"
                    className={`w-full px-3 py-2 pr-20 text-[11px] text-gray-900 rounded-lg 
                      ${otpStates.email.verified 
                        ? "border-green-500" 
                        : "border-gray-200"
                      } focus:outline-none focus:ring-1 focus:ring-gray-500/20 focus:border-gray-900
                      transition-all duration-300`}
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    disabled={otpStates.email.verified}
                  />
                  {!otpStates.email.show && !otpStates.email.verified && (
                    <button
                      onClick={() => handleButtonClick("email")}
                      className="absolute right-1 top-1/2 -translate-y-1/2 px-3 py-1 text-[11px] font-medium 
                        bg-gray-900 hover:bg-gray-800 text-white rounded-md transition-all duration-300"
                      disabled={loading}
                    >
                      Get OTP
                    </button>
                  )}
                  {otpStates.email.verified && (
                    <div className="absolute right-3 top-1/2 -translate-y-1/2 text-green-500">
                      <CheckRoundedIcon className="w-4 h-4" />
                    </div>
                  )}
                </div>
                {otpStates.email.show && !otpStates.email.verified && (
                  <OTPInput
                    value={otpStates.email.code}
                    onChange={(value) =>
                      setOtpStates((prev) => ({
                        ...prev,
                        email: { ...prev.email, code: value },
                      }))
                    }
                    onVerify={() => handleVerify("email")}
                    loading={loading}
                    autoFocus
                  />
                )}
              </div>

              <div className="space-y-1.5">
                <div className="relative">
                  <label className="absolute -top-2 left-2 px-1 bg-white text-[10px] text-gray-500">
                    Phone Number
                  </label>
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-[11px] text-gray-600">+91 |</span>
                  <input
                    type="tel"
                    className={`w-full px-3 py-2 pr-20 pl-12 text-[11px] text-gray-900 rounded-lg 
                      ${otpStates.phone.verified 
                        ? "border-green-500" 
                        : "border-gray-200"
                      } focus:outline-none focus:ring-1 focus:ring-gray-500/20 focus:border-gray-900
                      transition-all duration-300`}
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    disabled={otpStates.phone.verified}
                  />
                  {!otpStates.phone.show && !otpStates.phone.verified && (
                    <button
                      onClick={() => handleButtonClick("phone")}
                      className="absolute right-1 top-1/2 -translate-y-1/2 px-3 py-1 text-[11px] font-medium 
                        bg-gray-900 hover:bg-gray-800 text-white rounded-md transition-all duration-300"
                      disabled={loading}
                    >
                      Get OTP
                    </button>
                  )}
                  {otpStates.phone.verified && (
                    <div className="absolute right-3 top-1/2 -translate-y-1/2 text-green-500">
                      <CheckRoundedIcon className="w-4 h-4" />
                    </div>
                  )}
                </div>
                {otpStates.phone.show && !otpStates.phone.verified && (
                  <OTPInput
                    value={otpStates.phone.code}
                    onChange={(value) =>
                      setOtpStates((prev) => ({
                        ...prev,
                        phone: { ...prev.phone, code: value },
                      }))
                    }
                    onVerify={() => handlePhoneVerify()}
                    loading={loading}
                    autoFocus
                  />
                )}
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="space-y-1.5">
              <h1 className="text-lg font-semibold tracking-tight">Complete Your Profile</h1>
              <p className="text-[11px] text-gray-600">
                Tell us more about yourself
              </p>
            </div>

            <div className="space-y-4">
              <div className="space-y-1.5">
                <div className="relative">
                  <label className="absolute -top-2 left-2 px-1 bg-white text-[10px] text-gray-500">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 text-[11px]  text-gray-900 rounded-lg 
                      border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-500/20 
                      focus:border-gray-900 transition-all duration-300"
                    required
                  />
                </div>
              </div>

              <div className="space-y-1.5">
                <div className="relative">
                  <label className="absolute -top-2 left-2 px-1 bg-white text-[10px] text-gray-500">
                    Annual Income
                  </label>
                  <select
                    name="annualIncome"
                    value={formData.annualIncome}
                    onChange={(e) => handleSelectChange("annualIncome", e.target.value)}
                    className="w-full px-3 py-2 text-[11px]  text-gray-900 rounded-lg 
                      border border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-500/20 
                      focus:border-gray-900 transition-all duration-300 appearance-none"
                  >
                    <option value="">Select annual income</option>
                    <option value="Below ₹5L">Below ₹5L</option>
                    <option value="₹5L-₹10L">₹5L-₹10L</option>
                    <option value="₹10L-₹20L">₹10L-₹20L</option>
                    <option value="Above ₹20L">Above ₹20L</option>
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 pointer-events-none" />
                </div>
              </div>

              <div className="space-y-1.5">
                <div className="relative">
                  <label className="absolute -top-2 left-2 px-1 bg-white text-[10px] text-gray-500">
                    Preferred Investment Amount
                  </label>
                  <select
                    name="preferredInvestmentAmount"
                    value={formData.preferredInvestmentAmount}
                    onChange={(e) => handleSelectChange("preferredInvestmentAmount", e.target.value)}
                    className="w-full px-3 py-2 text-[11px] text-gray-900 rounded-lg 
                      border border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-500/20 
                      focus:border-gray-900 transition-all duration-300 appearance-none"
                  >
                    <option value="">Select preferred investment amount</option>
                    <option value="₹5,000 - ₹25,000">₹5,000 - ₹25,000</option>
                    <option value="₹25,000 - ₹50,000">₹25,000 - ₹50,000</option>
                    <option value="₹50,000 - ₹1,00,000">₹50,000 - ₹1,00,000</option>
                    <option value="₹1,00,000 - ₹5,00,000">₹1,00,000 - ₹5,00,000</option>
                    <option value="₹5,00,000 - ₹10,00,000">₹5,00,000 - ₹10,00,000</option>
                    <option value="₹10,00,000+">₹10,00,000+</option>
                  </select>
                  <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 pointer-events-none" />
                </div>
              </div>

              <div className="space-y-1.5">
                <label className="text-[10px] text-gray-500">
                  Investment Goal
                </label>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <div className="grid grid-cols-3 gap-2">
                    {['Short-term returns', 'Long-term appreciation', 'Passive income'].map((option) => (
  <div
    key={option}
    className={`relative pl-2 pr-2 pt-[0.4rem] pb-[0.4rem] border rounded-lg cursor-pointer hover:shadow-md hover:bg-gray-50 transition-all duration-300 
      ${
        formData.investmentGoal === option
          ? "border-gray-900 bg-gray-100"
          : "border-gray-200"
      }`}
    onClick={() => handleSelectChange("investmentGoal", option)}
  >
    {/* Tick Circle */}
    {formData.investmentGoal === option && (
      <div className="absolute top-2 right-2 w-5 h-5 border-2 rounded-full flex items-center justify-center bg-white">
        <i className="fa-solid fa-circle-check"></i>
      </div>
    )}

    {/* Content in Y Direction */}
    <div className="flex flex-col items-left text-left space-y-2">
      {/* Break the phrase into lines */}
      <h2 className="text-[11px] text-gray-800">
        {option.split(' ').map((word, index) => (
          <span key={index}>
            {word}
            {index < option.split(' ').length - 1 && <br />} {/* Add a break after each word except the last one */}
          </span>
        ))}
      </h2>
    </div>
  </div>
))}

                     </div>
                   </div>  
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
  <div className="space-y-2">
    <h1 className="text-sm font-semibold tracking-tight">What best describes your current occupation?</h1>
  </div>

  <div className="space-y-4">
    <div className="space-y-2">
      <div className="grid grid-cols-2 gap-2">
        {[
          {
            label: "Salaried Professional",
            value: "salaried-professional",
            logo: "user-tie",
          },
          {
            label: "Entrepreneur",
            value: "business-time",
            logo: "business-time",
          },
          {
            label: "Student",
            value: "student",
            logo: "graduation-cap",
          },
          {
            label: "Retired",
            value: "retired",
            logo: "hand-holding-hand",
          },
          {
            label: "Freelancer/Consultant",
            value: "freelancer-consultant",
            logo: "person-chalkboard",
          },
          {
            label: "Homemaker",
            value: "homemaker",
            logo: "house",
          },
          {
            label: "Investor/Trader",
            value: "investor-trader",
            logo: "money-bill-trend-up",
          },
          {
            label: "Other",
            value: "smth-else",
            logo: "wand-sparkles",
          },
        ].map((field) => (
          <div
            key={field.value}
            className={`relative pl-2 pr-2 pt-[0.4rem] pb-[0.4rem] border rounded-lg cursor-pointer hover:shadow-md hover:bg-gray-50 transition-all duration-300 
              ${
                formData.helpField === field.value
                  ? "border-gray-900 bg-gray-100"
                  : "border-gray-300"
              }`}
            onClick={() => handleSelectChange("helpField", field.value)}
          >
            {/* Tick Circle */}
            {formData.helpField === field.value && (
              <div className="absolute top-2 right-2 w-5 h-5 border-2 rounded-full flex items-center justify-center bg-white">
                <i className="fa-solid fa-circle-check"></i>
              </div>
            )}

            {/* Content in Y Direction */}
            <div className="flex flex-col items-left text-left space-y-2">
              {/* Box around the Logo */}
              <div className="flex text-xs justify-center items-center w-6 h-6 border border-gray-300 rounded-lg">
                <i className={`fa-solid fa-${field.logo}`}></i>
              </div>
              {/* Text */}
              <div>
                <h2 className="text-xs font-semibold text-gray-800">
                  {field.label}
                </h2>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</>


        );
      default:
        return null;
    }
  };

  return (
    <>
    <div className="flex flex-col lg:flex-row lg:h-[420px]">
      {!isSmallScreen && (<div className="w-full lg:w-1/2 h-[330px] lg:h-full relative rounded-t-lg lg:rounded-t-none lg:rounded-l-lg overflow-hidden">
        <video
          className="absolute inset-0 w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={video} type="video/mp4" />
        </video>
      </div>)}

      <div className="w-full lg:w-1/2 flex items-center justify-center bg-white rounded-b-lg lg:rounded-b-none lg:rounded-r-lg relative p-6 lg:py-6 py-10  overflow-y-auto lg:overflow-y-visible">
        <div className={`w-full max-w-[300px] ${step !== 3 ? "space-y-4" : "space-y-3"} mx-auto md:mt-2 md:mb-8`}>
          {step > 1 && (
            <button
              onClick={() => setStep(step - 1)}
              className="absolute bg-white top-2 left-2 p-1.5 hover:bg-gray-100 rounded-full transition-colors"
            >
              <ArrowLeft className="w-5 h-5 text-[#44475b]" />
            </button>
          )}

          {error && (
            <div className="p-2 text-[11px] bg-red-50 border border-red-200 text-red-600 rounded-lg">
              {error}
            </div>
          )}

          {renderStep()}

          <button
            onClick={handleContinue}
            className="w-full py-2 text-[11px] font-medium text-white bg-gray-900 hover:bg-gray-800 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={
              loading || 
              (step === 1 && (!otpStates.email.verified )) ||
              (step === 2 && (!formData.name || !formData.annualIncome || !formData.investmentGoal || !formData.preferredInvestmentAmount))
            }
          >
            {loading ? (
              <Loader className="w-3 h-3 animate-spin mx-auto" />
            ) : (
              step === 3 ? "Submit" : "Continue"
            )}
          </button>

          {step === 1 && (
            <>
              <div className="text-center text-[11px] text-gray-600">
                Already have an account? 
                <Link
                  onClick={() => {
                    setIsLoginModalOpen(true); // Open the login modal
                    onClose(); // Close the current modal
                  }}
                  className="text-[11px] text-gray-600 hover:text-gray-900"
                ><span className="text-blue-600 hover:text-blue-800"> Log in </span> 
                </Link>
                here to continue.
              </div>

              {/* <div className="space-y-2.5">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-200"></div>
                  </div>
                  <div className="relative flex justify-center text-[11px]">
                    <span className="px-2 text-gray-500 bg-white">Or continue with</span>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <button
                    onClick={login}
                    disabled={loading || !!socialLoading}
                    className="flex items-center justify-center px-3 py-1.5 text-[11px] font-medium text-white bg-gray-600 rounded-lg hover:bg-gray-900 disabled:opacity-50 transition-all"
                  >
                    {socialLoading === "google" ? (
                      <Loader className="w-3 h-3 animate-spin" />
                    ) : (
                      <>
                        <GoogleIcon className="w-1 h-1 mr-1" />
                        Google
                      </>
                    )}
                  </button>
                   <button
                    onClick={() => handleSocialSignIn("linkedin")}
                    disabled={loading || !!socialLoading}
                    className="flex items-center justify-center px-3 py-1.5 text-[11px] font-medium text-white bg-gray-600 rounded-lg hover:bg-gray-900 disabled:opacity-50 transition-all"
                  >
                    {socialLoading === "linkedin" ? (
                      <Loader className="w-3 h-3 animate-spin" />
                    ) : (
                      <>
                        <LinkedInIcon className=" text-[100px] w-1 h-1 mr-1" />
                        LinkedIn
                      </>
                    )}
                  </button> 
                </div>
              </div> */}
            </>
          )}
        </div>
        <div className="absolute bottom-6 left-1/2 -translate-x-1/2 flex gap-2">
          {[0, 1, 2].map((index) => (
            <div
              key={index}
              className={`w-8 h-0.5 ${
                index < step ? 'bg-gray-900' : 'bg-gray-300'
              } rounded-full`}
              aria-hidden="true"
            />
          ))}
        </div>
      </div>
      
    </div>
    <Modal
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)}
      >
        <OtplessLogin />
      </Modal>
    </>
  );
};
export default OptlessSignup;
