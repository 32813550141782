import {
  Box,
  Typography,
  Grid,
  CardMedia,
  styled,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import axios from "axios";
import config from "../../../config";
import Navbar from "../../../Navbar";

const arrow = ">";

const PropertyLink = styled(Link)`
  text-decoration: none;
  color: black;
  margin-right: 10px;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const Photos = ({ clicked, setClicked, showSecondLastImage = false }) => {
  const { id } = useParams(); // Access the ID from the URL params
  const URL = config.URL;
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [listing, setListing] = useState({});
  const [mainImage, setMainImage] = useState(""); // Main image state
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userinfo"));
    if (token) {
      setLoggedIn(true);
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const response = await axios.get(`${URL}/listing/${id}`);
        setListing(response.data);

        // Determine which image to set as the main image
        if (showSecondLastImage && response.data.images?.length > 1) {
          setMainImage(response.data.images[response.data.images.length - 2]);
        } else {
          setMainImage(response.data.images?.[0] || "");
        }
      } catch (error) {
        console.error("Error fetching listing:", error);
      }
    };

    fetchListing();
  }, [id, showSecondLastImage]);

  const handlePrevious = () => {
    const currentIndex = listing.images?.indexOf(mainImage);
    if (currentIndex > 0) {
      setMainImage(listing.images[currentIndex - 1]);
    }
  };

  const handleNext = () => {
    const currentIndex = listing.images?.indexOf(mainImage);
    if (currentIndex < listing.images?.length - 1) {
      setMainImage(listing.images[currentIndex + 1]);
    }
  };

  return (
    <div>
      {!isLoggedIn && <Navbar />}
      <Box style={{ padding: "30px 30px 30px 30px" }}>
        {/* Header Section: Properties > Property Heading */}
        <Box style={{ marginBottom: "20px" }}>
          <Box style={{ display: "flex" }}>
            <PropertyLink to={isLoggedIn ? `/dashboard/properties/view/${id}` : `/properties/${id}`}>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontWeight: "600",
                  paddingLeft: "10px",
                }}
              >
                {listing.properyheading}
              </Typography>
            </PropertyLink>

            {arrow}
            <Typography
              style={{
                color: "#a3abba",
                marginLeft: "10px",
                fontFamily: "Inter",
                fontSize: "15px",
              }}
            >
              Photos
            </Typography>
          </Box>
        </Box>

        {/* Photo Gallery Section */}
        <Box
          style={{
            display: "flex",
            justifyContent: "center", // Centers the photo gallery horizontally
            flexDirection: "column", // Stacks the images vertically
            alignItems: "center", // Ensures the images are centered in the container
            width: "100%",
            position: "relative", // Enables arrow positioning over the main image
          }}
        >
          {/* Main Image */}
          {mainImage && (
            <PhotoProvider>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  width: "100%",
                }}
              >
                <PhotoView src={mainImage}>
                  <CardMedia
                    component="img"
                    height="400"
                    image={mainImage}
                    alt="Main property"
                    style={{
                      marginBottom: "20px",
                      borderRadius: "10px",
                      maxWidth: isSmallScreen ? "100%" : isLoggedIn ? "70%" : "40%",
                      objectFit: "cover",
                    }}
                  />
                </PhotoView>

                {/* Left Arrow */}
                <IconButton
                  onClick={handlePrevious}
                  style={{
                    position: "absolute",
                    left: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: listing.images?.indexOf(mainImage) === 0 ? "lightgray" : "black",
                    fontSize: "30px",
                  }}
                >
                  <i className="fa-solid fa-chevron-left"></i>
                </IconButton>

                {/* Right Arrow */}
                <IconButton
                  onClick={handleNext}
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: listing.images?.indexOf(mainImage) === listing.images?.length - 1 ? "lightgray" : "black",
                    fontSize: "30px",
                  }}
                >
                  <i className="fa-solid fa-chevron-right"></i>
                </IconButton>
              </Box>
            </PhotoProvider>
          )}

          {/* Smaller Images */}
          <Grid container spacing={2} justifyContent="center">
            {listing.images?.map((image, index) => (
              <Grid item xs={4} sm={3} md={2} key={index}>
                <CardMedia
                  component="img"
                  height="100"
                  image={image}
                  alt={`Property ${index}`}
                  style={{
                    cursor: "pointer",
                    borderRadius: "5px",
                    border: image === mainImage ? "2px solid #0170dc" : "none",
                  }}
                  onClick={() => setMainImage(image)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Photos;
